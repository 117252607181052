import { useEffect, useState } from "react";
import RecruiterCard from "../../components/recruiter/RecruiterCard";
import { CiSearch } from "react-icons/ci";
import Modal from "../../components/Modal";
import { FaRegFlag } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import badge from "../../assets/badge.png";
import Sidebar2 from "../../components/sidebar2/Sidebar2";
import { RecruiterService } from "../../services/RecruiterService";
import LoadingSpinner from "../../components/Loading";
import { imgUserDefault } from "../../const/const";
import { formatDate, formatHour } from "../../commun/helpers";
import { IoIosCloseCircle } from "react-icons/io";

const Recruiters = ({ title, user }) => {
  const _recruiterService = new RecruiterService();

  const [recruiters, setRecruiters] = useState([]);
  const [loading, setLoading] = useState(true);

  const [recruitersFilter, setRecruitersFilter] = useState({});
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [numberPhone, setNumberPhone] = useState("");

  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
    recruitersController();
  }, [title]);

  const recruitersController = async () => {
    const response = await _recruiterService.recruiters();

    if (response.status) {
      setRecruiters(response.res.data.recruiters);
      setRecruitersFilter(response.res.data.recruiters);

      setLoading(false);
    } else console.error(response);
  };

  const [modal, setModal] = useState(false);
  const [recruiter, setRecruiter] = useState({});

  const handleChangeUser = (user) => {
    setRecruiter(user);
    setModal(true);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setNumberPhone(value);

    const filteredRecruiters = recruiters.filter((recruiter) =>
      recruiter.contact.includes(value)
    );

    setRecruitersFilter(filteredRecruiters);
  };

  const DetailUserModal = () => {
    return (
      <>
        <div
          className={"flex flex-col items-center justify-center space-y-10 "}
        >
          <div className={"bg-blue-500 w-full py-10 rounded-b-3xl"}>
            <div className={"flex flex-col items-center gap-5"}>
              <div>
                <img
                  src={recruiter.photo ?? imgUserDefault}
                  alt="user"
                  className={
                    "w-32 lg:w-40 h-32 lg:h-40 border-2 border-white rounded-full"
                  }
                />
              </div>
              <div className={"font-bold text-white text-xl lg:text-3xl"}>
                {`${recruiter.nom} ${recruiter.prenom}`}
              </div>
            </div>
          </div>
          <div
            className={"flex flex-col w-full gap-5 px-10 pb-10 mb-10 border-b"}
          >
            <div className={"flex items-start gap-10"}>
              <div>
                <FaRegFlag size={30} />
              </div>
              <div>
                {recruiter.ville.pays.nom}, {recruiter.ville.nom}
              </div>
            </div>
            <div className={"flex items-start gap-10"}>
              <div>
                <FiPhoneCall size={30} />
              </div>
              <div>{recruiter.contact}</div>
            </div>
            <div className={"flex items-start gap-10"}>
              <div>
                <MdOutlineMarkEmailRead size={30} />
              </div>
              <div>{recruiter.user.email}</div>
            </div>
          </div>
          <div
            className={"flex flex-col w-full gap-5 px-10 pb-10 mb-10 border-b"}
          >
            <div className={"flex flex-col gap-5"}>
              <div>Date et heure de création du compte</div>
              <div className={"lg:px-10"}>
                {formatDate(recruiter.created_at)} à{" "}
                {formatHour(recruiter.created_at)}
              </div>
            </div>
          </div>
          <div
            className={"flex flex-col w-full gap-5 px-10 pb-10 mb-10 border-b"}
          >
            {recruiter.status_badge !== "0" ? (
              <div className={"flex flex-col gap-5"}>
                <div className={"text-green-500"}>Badge certifié</div>
                <div className={"lg:px-10 flex"}>
                  <div className={"flex-1"}>
                    <div>
                      Reférence :
                      <span className={"font-bold"}>
                        {recruiter.code_badge}
                      </span>
                      ,
                    </div>
                    <div>
                      Date d&apos;expiration :
                      <span className={"font-bold"}>
                        {formatDate(recruiter.badge_expired)}
                      </span>
                      .
                    </div>
                  </div>
                  <div>
                    <img
                      src={badge}
                      alt="user"
                      className={"w-12 h-12 rounded-full"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={"flex flex-col gap-5"}>
                <div>Badge non certifié</div>
                <div className={"lg:px-10 flex"}>
                  <div className={"flex-1"}>
                    Invitez le récruteur à certifier son badge pour bénéfier
                    pour chaque vente de son vendeur un commission de
                    <span className={"font-bold"}>1000 FCFA</span>.
                  </div>
                  <div>
                    <img
                      src={badge}
                      alt="user"
                      className={"w-14 h-12 rounded-full"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={"flex flex-col w-full gap-5 px-10 pb-10 mb-10"}>
            <div className={"flex flex-col gap-5"}>
              <div>Frais de création de compte</div>
              <div className={"font-bold"}>1000 F CFA</div>
              <div className={"lg:px-10 text-red-500"}>
                Paiement non éffectué
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="flex gap-4 lg:w-[54vw]">
          <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
            <div
              className={
                "rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
              }
            >
              <div
                className={
                  "shadow-md flex justify-between items-center  bg-white rounded-t-2xl px-3"
                }
              >
                <div className={"p-5 text-lg"}>Les recruteurs</div>
                <div className="flex gap-5">
                  {showInputSearch && (
                    <input
                      type={"search"}
                      placeholder="Numéro de téléphone"
                      className="w-[100%] rounded-lg border-blue-500"
                      value={numberPhone}
                      onChange={(value) => handleSearch(value)}
                    />
                  )}
                  <div
                    className={"p-2 rounded-full bg-gray-100 cursor-pointer"}
                    onClick={() => setShowInputSearch(!showInputSearch)}
                  >
                    {showInputSearch ? (
                      <IoIosCloseCircle size={30} />
                    ) : (
                      <CiSearch size={30} />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  "border-gray-100  flex flex-col space-y-5 justify-center p-5"
                }
              >
                {recruitersFilter.isEmpty ? (
                  <div className="text-center font-bold">
                    Recruteur introuvable...
                  </div>
                ) : (
                  recruitersFilter.map((user, index) => (
                    <div key={index} onClick={() => handleChangeUser(user)}>
                      <RecruiterCard user={user} />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <Sidebar2 />
        </div>
      )}
      <Modal
        className={"p-0"}
        header={true}
        size={"3xl"}
        textHeader={"Profil"}
        open={modal}
        setOpen={setModal}
      >
        <DetailUserModal />
      </Modal>
    </>
  );
};

export default Recruiters;

import { useState } from "react";

const Input = ({ icon: Icon, type = "text", className, placeholder = "", id, value, label = null, onChange }) => {

    const [focus, setFocus] = useState(false);

    const handleChangeColor = (state) => {
        setFocus(state === 'focus');
    }

    return (
        <>
            {label &&
                <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
                    {label}
                </label>
            }

            <div className="relative mb-0">
                {Icon &&
                    <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                        <Icon size={30} color={focus ? "blue" : "gray"} />
                    </div>
                }

                <input
                    type={type}
                    id={id}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={() => handleChangeColor("focus")}
                    onBlur={() => handleChangeColor("blur")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ${className} ${Icon ? 'ps-14' : ''}`}
                    placeholder={placeholder}
                />
            </div>
        </>
    );
}

export default Input;
import { NavLink, useLocation } from "react-router-dom";

const LinkSidebar = ({ link, Icon, title }) => {
  
    const location = useLocation();
    const { pathname } = location;

  return (
    <li>
      <NavLink
        to={link}
        className={`group relative flex items-center gap-5 rounded-sm py-2 font-medium duration-300 ease-in-out ${
          pathname.includes(link) && "text__color__yellow"
        }`}
      >
        <Icon size={28} />
        <div className={""}>{title}</div>
      </NavLink>
    </li>
  );
};

export default LinkSidebar;



export default class Api {
  // Base URL for all API routes
  static base = `${process.env.REACT_APP_API_URL}ambassador/`;

  // Guest routes
  // Routes that do not require authentication
  static sendOTP = `${this.base}login`;

  // Auth routes
  // Routes that require authentication
  static login = `${this.base}login/confirm`;

  //Globals routes
  static countries = `${this.base}countries`;

  //Auth routes
  //user Detail routes
  static detail = `${this.base}detail`;
  static wallet = `${this.base}wallet`;
  static withdrawal = `${this.base}transaction/withdrawal`;
  static transaction_commission_recruiter = `${this.base}transaction_commission_recruiter`;
  static logout = `${this.base}logout`;

  // Recruiter routes
  static recruiters = `${this.base}recruiter/index`;
  static recruiter = `${this.base}recruiter/`;
  static recruiterSendOTP = `${this.base}recruiter/sendOTP`;
  static recruiterStore = `${this.base}recruiter/store`;

  // Order routes
  static orders = `${this.base}order/index`;
  static orders_in_progress = `${this.base}order/in_progress`;
  static orders_received = `${this.base}order/received`;
  static orders_success = `${this.base}order/success`;
  static orders_cancel = `${this.base}order/cancel`;
  static order_status = `${this.base}order/status/`;

}
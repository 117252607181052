import {Route, Routes} from "react-router-dom";
import SellersList from "../../pages/recruiter_account/SellersList";
import AddSeller from "../../pages/recruiter_account/AddSeller";
import Stats from "../../pages/recruiter_account/Stats";
import WalletAccountRecruiter from "../../pages/recruiter_account/WalletAccountRecruiter";

const AccountRecruiter = ({user}) => {
    return (
      <>
        <Routes>
          <Route
            path="/sellers-list"
            element={<SellersList user={user} title={"Liste des récrus"} />}
          />
          <Route
            path="/add-seller"
            element={<AddSeller title={"Ajout de vendeur"} />}
          />
          <Route
            path="/statistic"
            element={<Stats user={user} title={"Statistique"} />}
          />
          <Route
            path="/wallet"
            element={
              <WalletAccountRecruiter user={user} title={"Portefeuille"} />
            }
          />
        </Routes>
      </>
    );
}

export default AccountRecruiter;
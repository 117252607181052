import user from "../../assets/user.jpg";
import badge from "../../assets/badge.png";

const RecruiterWalletCard = ({fullname, createdAt, city}) => {
    return (
        <>
            <div className={'lg:flex justify-between items-center shadow flex-1 bg-white rounded-lg py-2'}>
                <div className={'flex-1 lg:flex border-r'}>
                    <div className={'flex flex-1 justify-center lg:justify-start items-center gap-4 px-2 border-r'}>
                        <div>
                            <img src={user} alt="user" className={'w-20 h-20 border-2 border-blue-300 rounded-full'}/>
                        </div>
                        <div className={'flex-1 flex flex-col'}>
                            <div className={'text-xl font-[600]'}>{fullname}</div>
                            <div className={'text-sm'}>Inscrit le {createdAt}</div>
                            <div className={'text-gray-600 text-sm'}>Côte d&apos;ivoire, {city}</div>

                        </div>
                    </div>
                    <div className={'flex flex-col justify-center text-sm text-gray-600 px-6 lg:px-12 border-r'}>
                        <div className={'flex flex-col items-center px-2'}>
                            <img src={badge} alt="user" className={'w-10 h-10 rounded-full'}/>
                            <div className={'text-[12px] '}>18/12/2024 à 10:10</div>
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col items-center px-6 lg:px-12 text-blue-500 text-[22px] font-bold'}>
                    1 000 FCFA
                </div>
            </div>
        </>
    )
}

export default RecruiterWalletCard;
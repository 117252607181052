import React, { useState } from 'react';
import { currency, currencyNoDevice } from "../../commun/helpers";
import Modal from "../Modal";
import Input from "../Input";
import _org from '../../assets/operators/orange.jpg';
import _mtn from '../../assets/operators/mtn.jpg';
import _moov from '../../assets/operators/moov.jpg';
import _wave from '../../assets/operators/wave.png';
import ButtonComponent from "../Button";
import { ToastSweet } from "../Loading";
import { WalletService } from "../../services/WalletService";

const Wallet = ({ wallet, className }) => {
    const _walletService = new WalletService();
    const today = new Date().toISOString().split('T')[0];

    const transactionsToday = wallet.transactions.filter(transaction =>
        transaction.created_at.startsWith(today)
    );

    const totalCommissionsToday = transactionsToday
        .filter(transaction => transaction.type === 'commission')
        .reduce((total, transaction) => total + transaction.montant, 0);

    const totalWithdrawalsToday = transactionsToday
        .filter(transaction => transaction.type === 'retrait')
        .reduce((total, transaction) => total + (transaction.montant || 0), 0);

    const [modal, setModal] = useState(false);
    const [operator, setOperator] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [amount, setAmount] = useState(0);

    const resetFormWithDrawal = () => {
        setOperator('');
        setPhoneNumber('');
        setAmount(0);
    }

    const operators = [
        { name: 'MTN', logo: _mtn },
        { name: 'Orange', logo: _org },
        { name: 'Moov', logo: _moov },
        { name: 'Wave', logo: _wave },
    ];

    const handleChange = () => {
        setModal(true);
    };

    const handleSubmit = async () => {
        console.log(operator, amount, phoneNumber)
        if (operator === '' || amount === 0 || phoneNumber === '')
            return;

        const res = await _walletService.withdrawal({
            phone_number: phoneNumber,
            operator,
            amount,
        });

        if (res.status) {
            ToastSweet.fire({
                icon: 'success',
                title: 'Rétrait en cours',
            });

            resetFormWithDrawal();
            setModal(false);
        } else {
            console.log(res.e);
            ToastSweet.fire({
                icon: 'error',
                title: 'Erreur',
            });
        }
    }

    return (
        <>
            <div
                className={`bg-blue-600 rounded-2xl h-72 px-4 lg:px-14 flex-1 flex justify-center space-y-5 flex-col ${className}`}
            >
                <div className="text-white text-2xl">Solde générale</div>
                <div className="flex justify-between">
                    <div className="text-white text-2xl lg:text-6xl font-bold">
                        {currencyNoDevice(wallet.somme)}
                        <span className={"mx-2 text-base lg:text-lg font-normal"}>
                            CFA
                        </span>
                    </div>
                    {
                        wallet.somme > 0 && <button
                            onClick={() => handleChange()}
                            className={
                                "p-2 lg:px-10 bg-white rounded-xl text-2xl text-blue-600  font-bold flex items-center"
                            }
                        >
                            Retirer
                        </button>
                    }
                </div>
                <div className="text-white flex justify-start">
                    <div
                        className={
                            "flex flex-col font-bold border-r px-3 lg:px-8 border-white"
                        }
                    >
                        <div className="text-lg">Reçu aujourd&apos;hui</div>
                        <div className="text-2xl">{currency(totalCommissionsToday)}</div>
                    </div>
                    <div className={"flex flex-col font-bold px-3 lg:px-8 "}>
                        <div className="text-lg">Retiré aujourd&apos;hui</div>
                        <div className="text-2xl"> {currency(totalWithdrawalsToday)}</div>
                    </div>
                </div>
            </div>
            {
                wallet.somme > 0 && <Modal
                    className={"p-0"}
                    header={true}
                    size={"2xl"}
                    textHeader={"Demande de rétrait"}
                    open={modal}
                    setOpen={setModal}
                >
                    <div>
                        <div className={'flex justify-evenly'}>
                            {
                                operators.map((op, index) => (
                                    <div key={index} className={'p-5'}>
                                        <div className={`flex flex-col items-center space-y-4 p-4 rounded border cursor-pointer  ${op.name === operator ? 'border-amber-300' : ''} `} onClick={() => setOperator(op.name)}>
                                            <div><img src={op.logo} alt={index} className={'w-48'} /></div>
                                            <div>{op.name}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={'p-4 px-10'}>
                            <Input
                                id={'phone_number'}
                                placeholder={'Numéro de phone sans le préfix'}
                                value={phoneNumber}
                                name={'phone_number'}
                                onChange={(e) => setPhoneNumber(e.value)}
                            />
                        </div>
                        <div className={'p-4 px-10'}>
                            <Input
                                id={'amount'}
                                type="number"
                                placeholder={'Montant à rétirer'}
                                value={amount}
                                onChange={(e) => console.log(e.value)}
                            />
                        </div>
                        <div className={"flex justify-center p-10"}>
                            <ButtonComponent
                                type={"button"}
                                onClick={handleSubmit}
                                className={"w-full lg:w-96 bg-blue-900"}
                                label={"ENVOYER"}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default Wallet;

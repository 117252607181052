import ModalComponent from "../Modal";
import {useState} from "react";
import confirm_icon from "../../assets/confirm.png";
import {currency, formatDate} from "../../commun/helpers";

const DetailOrderDelivery = ({order, status, clickStatusOrder}) => {

    const [modal, setModal] = useState(false);
    const [choice, setChoise] = useState(null);

    const handleOpenModal = (choiceSelect) => {

        setChoise(choiceSelect);
        setModal(true);

    }

    const statusOrder = (type = 'text') => {
        if(type === 'bg')
            return status === '1' ? 'bg-blue-600' : 'bg-green-400';
        if(type === 'text')
            return status === '1' ? 'text-blue-600' : 'text-green-400';
        if(type === 'header')
            return status === '1' ? 'Commande en route' : 'Commande reçue, en attente du client';
        if(type === 'status')
            return status === '1' ? 'En route' : 'Reçue';
    }

    const confirmed = () => {
        setModal(false);
        clickStatusOrder(order.id, 'go_received');
    }

    const Confirmation = () => {
        return (
            <div className={'flex flex-col justify-between h-72 '}>
                <div className={'flex justify-center'}><img src={confirm_icon} alt="confirmàlogo"/></div>
                <div className={'text-center'}>Voulez-vous vraiment marquer cette commande <span className={'text-green-500 uppercase'}>reçue</span> ?</div>
                <div className={'flex justify-between px-10'}>
                    <div onClick={() => setModal(false)} className={'cursor-pointer font-medium text-lg'}>NON</div>
                    <div onClick={confirmed} className={'text-green-500 cursor-pointer  font-medium text-lg'}>OUI</div>
                </div>
            </div>
        );
    }

    const finished = () => {
        setModal(false);
        clickStatusOrder(order.id, 'go_validated');
    }

    const Finish = () => {
        return (
            <div className={'flex flex-col justify-between h-72 '}>
                <div className={'flex justify-center'}><img src={confirm_icon} alt="confirmàlogo"/></div>
                <div className={'text-center'}>Voulez-vous vraiment <span className={'text-blue-500'}>terminer</span> la commande?</div>
                <div className={'flex justify-between px-10'}>
                    <div onClick={() => setModal(false)} className={'cursor-pointer font-medium text-lg'}>NON</div>
                    <div onClick={finished} className={'text-blue-500 cursor-pointer  font-medium text-lg'}>OUI</div>
                </div>
            </div>
        );
    }

    const Cancel = () => {
        return (
            <div className={'flex flex-col justify-between h-72 '}>
                <div className={'flex justify-center'}><img src={confirm_icon} alt="confirmàlogo"/></div>
                <div className={'text-center'}>Voulez-vous vraiment <span className={'text-red-500'}>annuler</span> la commande?</div>
                <div className={'flex justify-between px-10'}>
                    <div onClick={() => setModal(false)} className={'cursor-pointer font-medium text-lg'}>NON</div>
                    <div onClick={() => setModal(false)} className={'text-red-500 cursor-pointer  font-medium text-lg'}>OUI</div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="w-[33vw] h-[85vh] pt-5 xl:pt-0 rounded-xl xl:block pr-9">
                <div className="flex gap-4">
                    <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
                        <div
                            className={'rounded-2xl pb-1 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'}>
                            <div className={`shadow-md flex justify-between items-center ${statusOrder('bg')} text-white  rounded-t-2xl p-3 px-5`}>
                                <div>
                                    <div className={'text-xl font-medium'}>{statusOrder('header')}</div>
                                    <div>{formatDate(order.created_at)}</div>
                                </div>
                                <div>
                                    <div>{order.reference}</div>
                                </div>
                            </div>
                            <div
                                className={'border-gray-100 lg:min-h-[75vh] m-1 bg-white rounded-2xl flex flex-col justify-between p-2'}>
                                <div className={'flex flex-col space-y-5'}>
                                    <div className="flex flex-1 flex-col cursor-pointer rounded-xl bg-white shadow">
                                        <div className="md:flex justify-between relative">
                                            <div
                                                className="absolute font-medium top-0 right-0 p-1 px-4 text-sm bg__color__yellow rounded-l-lg rounded-tr-xl"
                                            >
                                                {order.produit.etat.nom}
                                            </div>
                                            <div className="flex flex-1 gap-6">
                                                <div className={'w-36 h-32 '}>
                                                    <img src={order.produit.photoprods[0].photo} alt="produit"
                                                         className={'w-32 h-32 object-cover rounded-xl rounded-r-none'}/>
                                                </div>
                                                <div className="flex flex-col justify-between py-2">
                                                    <div className={'pt-4'}>
                                                        <div
                                                            className="font-medium text-lg md:text-xl two__line__container">
                                                            {order.produit.nom}
                                                        </div>
                                                        <div
                                                            className="font-bold text-blue-500 text-sm md:text-2xl">
                                                            {currency(order.prix_proposition)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                order.produit.price_normal && (
                                                    <div className="flex flex-col justify-end py-1 px-2">
                                                        <div
                                                            className="text-sm text-end">{order.produit.prix_normal}</div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                    <div className={'space-y-2 px-10 pb-6 border-b'}>
                                        <div className={'flex justify-between'}>
                                            <div>Quatité</div>
                                            <div className={'font-medium'}>{order.qte} pièce(s)</div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Prix unitaire</div>
                                            <div
                                                className={'font-medium'}>{currency(order.prix_proposition)}
                                            </div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Prix d&apos;achat</div>
                                            <div
                                                className={'font-medium'}>{currency(order.prix_proposition * order.qte)}
                                            </div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Livraison {order.lieu_livraison}</div>
                                            <div
                                                className={'font-medium'}>{currency(order.frais_livraison * order.qte)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'space-y-2 px-10 pb-4'}>
                                        <div className={'flex justify-between font-bold text-xl'}>
                                            <div>TOTAL A PAYER</div>
                                            <div
                                                className={'text__color__yellow'}>{currency(order.total)}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={'text-center'}>Détails de la livraison</div>
                                        <div className={'flex justify-center py-2 pt-2'}>
                                            <div className={' w-10 h-[2px] bg-gray-200 mt-2'}></div>
                                        </div>
                                        <div className={'space-y-3 px-10 py-3 pb-6'}>
                                            <div className={'flex'}>
                                                <div className={'flex-1 space-y-3'}>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Nom</div>
                                                        <div>{order.nom}</div>
                                                    </div>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Contact</div>
                                                        <div>{order.contact1}</div>
                                                    </div>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Date de livraison</div>
                                                        <div>{formatDate(order.date_livraison)}</div>
                                                    </div>
                                                </div>
                                                <div className={'space-y-3'}>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Livraison</div>
                                                        <div>{order.lieu_livraison}</div>
                                                    </div>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Contact du vendeur</div>
                                                        <div>{order.vendeur.contact}</div>
                                                    </div>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Heure de livraison</div>
                                                        <div>{order.heure_livraison}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                    {
                                        status === '1' && (
                                            <div className={'flex justify-center gap-2 pt-10 lg:pt-0 space-y-2 lg:space-y-0'}>

                                            <div>
                                                <div
                                                    className={'p-2 px-24 cursor-pointer text-center text-white font-[500] bg-blue-600 rounded-lg uppercase'}
                                                    onClick={() => handleOpenModal('confirm')}
                                                >
                                                    REçu
                                                </div>
                                                <div
                                                    className={'text-center text-sm my-2 text-gray-700 cursor-pointer'}

                                                >
                                                    SIGNALER UN PROBLEME
                                                </div>
                                            </div>
                                        </div>

                                        )
                                    }

                                    {
                                        status === '2' && (
                                            <div className={'lg:grid grid-cols-2 gap-2 pt-10 lg:pt-0 space-y-2 lg:space-y-0'}>
                                                <div
                                                    className={'p-2 cursor-pointer text-center text-white font-[500] bg-blue-600 rounded-2xl'}
                                                    onClick={() => handleOpenModal('finish')}
                                                >
                                                    TERMINER
                                                </div>

                                                <div
                                                    className={'p-2 cursor-pointer text-center text-white font-[500] bg-rose-600 rounded-2xl '}
                                                    onClick={() => handleOpenModal('cancel')}

                                                >
                                                    Annuler
                                                </div>
                                            </div>
                                        )
                                    }



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent open={modal} setOpen={setModal} size={choice !== 'signal' ? 'sm' : 'xl'}>
                {
                    choice === 'confirm' ? (<Confirmation/>) : choice === 'finish' ? <Finish /> : choice === 'cancel' ? <Cancel /> : <div></div>
                }
            </ModalComponent>
        </>
    )
}

export default DetailOrderDelivery;

const ElementCardSidebar2 = ({product}) => {
    return <>
        <div className={'flex items-center gap-3 p-1 bg-white rounded-xl'}>
            <div>
                <img src={product.image} className={'w-16 h-16 rounded-xl bg-gray-200 '} alt=""/>
            </div>
            <div className={'flex-1 flex flex-col'}>
                <div className={'text-sm one__line__container'}>{product.relayPoint}</div>
                <div className={'text-sm one__line__container'}>Pour l&apos;annulation de la commande</div>
                <div className={'text-[10px] text-end'}>à l&apos;instant</div>
            </div>
        </div>
    </>
}

export default ElementCardSidebar2;
import { useEffect } from "react";
import {Link} from "react-router-dom";
import maintenance from '../assets/maintenance.svg';

 const Maintenance = ({ title }) => {

    useEffect(() => {
      document.title = `${title} - Ambassadeur`;
    }, [title]);

   return <>
   <div className={'bg-white h-[83vh] rounded-3xl py-16 px-12 space-y-10'}>
       <h1 className={'text-5xl -tracking-tighter text-stone-500 font-extrabold uppercase'}>Page en maintenance</h1>
       <div className={'flex h-full flex-col-reverse lg:flex-row'}>
           <div className={"flex-1 space-y-6"}>
               <div className={'text-stone-700 font-medium'}>
                   Nous procédons actuellement à une maintenance planifiée
                   Notre priorité est de vous offrir une expérience optimale.
                   C’est pourquoi nous effectuons des mises à jour régulières
                   Pour améliorer la performance et la fonctionnalité nos services.
               </div>
               <div className={'font-bold'}>
                   En attendant, vous pouvez profiter des services suivants :
               </div>
               <div>
                   <div>
                       Le point relais
                       <Link
                        to="/ambassador/relay_point/appointment"
                        className={'font-bold text-blue-500 px-2 font-bold'}
                       >
                           [voir]
                       </Link>
                   </div>
                   <div>
                       L’inscription des recruteurs
                       <Link
                           to="/ambassador/recruiter_registration/recruiters"
                           className={'font-bold text-blue-500 px-2 font-bold'}
                       >
                           [voir]
                       </Link>

                   </div>
               </div>
               <div className={'w-full text-center py-8'}>
                   <div>Merci de votre patience !</div>
               </div>

               <div className={'flex justify-center'}>
                   <Link
                       to={'/ambassador/dashboard'}
                       className={'bg__color__yellow px-10 py-2 rounded text-white font-bold hover:text-black uppercase'}
                   >
                        Accueil
                   </Link>
               </div>

           </div>
           <div className={'flex-1 flex flex-col justify-end mb-10'}>
               <div className={'flex justify-end'}>
                   <img src={maintenance} alt="maintenance" className={'w-96'}/>
               </div>
           </div>

       </div>
   </div>
   </>;
 };

export default Maintenance;
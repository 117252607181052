import {useEffect, useState} from "react";
import OrderCardDelivery from "../../components/order/OrderCardDelivery";
import DetailOrderDelivery from "../../components/order/DetailOrderDelivery";
import OrderService from "../../services/OrderService";
import LoadingSpinner, {ToastSweet} from "../../components/Loading";

const Delivery = ({ title, user }) => {

  const _orderService = new OrderService();

  const [ordersInProgress, setOrdersInProgress] = useState([]);
  const [ordersReceived, setOrdersReceived] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const data = async () => {
    const res = await _orderService.in_progress();
    if(res.status) {
      await setOrdersInProgress(res.res.data.orders);
      await setOrders(res.res.data.orders)
      if(res.res.data.orders.length > 0 && order == null)
        await setOrder(res.res.data.orders[0]);
    } else
      console.error(res.e);

  }

  const dataReceived = async (loa = false) => {
    const res = await _orderService.received();
    if(res.status) {
      await setOrdersReceived(res.res.data.orders);
      if(loa)
        await setOrders(res.res.data.orders);
      else if(res.res.data.orders.length > 0 && order == null)
      {
        await setOrders(res.res.data.orders);
        await setOrder(res.res.data.orders[0]);
        await setStatus(1);
      }
    } else
      console.error(res.e);
    setLoading(false);

  }

  const dataReceivedLoading = async () => {
    const res = await _orderService.received();
    if(res.status) {
      await setOrdersReceived(res.res.data.orders);
      await setOrders(res.res.data.orders);
      await setOrder(res.res.data.orders[0]);
    } else
      console.error(res.e);
    setLoading(false);

  }

  const changeStatusOrder = async (orderId, choiceSelect) => {
    if(choiceSelect === 'go_received')
    {
      const res = await _orderService.change_status(orderId, choiceSelect)
      if(res.status){
        setLoading(true);
        setStatus(1);
        setOrder(res.res.data.order);
        ToastSweet.fire({
          icon: "success",
          title: `Commande dans la liste des stocks.`,
        });
        await data();
        await dataReceived(true);
      }
    }

    if(choiceSelect === 'go_validated')
    {
      const res = await _orderService.change_status(orderId, choiceSelect)
      if(res.status){
        setLoading(true);
        ToastSweet.fire({
          icon: "success",
          title: `Commande validée.`,
        });
        await dataReceivedLoading();
      }
    }
      console.log(orderId, choiceSelect);
  }

  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
  }, [title]);

  useEffect(() => {
      const init = async () => {
        await data();
        await dataReceived();
      }
      init();
  },[])

  const [status, setStatus] = useState(0);
  const [order, setOrder] = useState(null);

  const handleChangeStatus = (code) => {
    if(code === 0)
      setOrders(ordersInProgress);
    else
      setOrders(ordersReceived);
    setStatus(code);
  };

  const handleUpdateOrder = (card) => {
    setOrder(card);
  };

  return (
    <>
      {loading ? (
              <LoadingSpinner loading={loading} />
          ) :
          (
              <div className="lg:flex gap-4 w-[82vw]">
                <div className={`flex-1 mx-auto min-w-[49vw] space-y-5`}>
                  <div
                      className={`rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] ${
                          !order ? "lg:w-[50vw]" : ""
                      }`}
                  >
                    <div
                        className={`shadow-md flex gap-10 items-end bg-white rounded-t-2xl px-10 text-[12px] lg:text-lg`}
                    >
                      {["En route", "Reçue"].map((label, index) => (
                          <div
                              key={index}
                              onClick={() => handleChangeStatus(index)}
                              className={`py-5 cursor-pointer ${
                                  status === index
                                      ? "border-b-2 border-yellow-500 text-yellow-500 font-semibold"
                                      : ""
                              }`}
                          >
                            {label}
                          </div>
                      ))}
                    </div>

                    <div
                        className={
                          "border-gray-100 max-h-[77vh] flex flex-col space-y-3 items-start p-3 overflow-y-auto"
                        }
                    >
                      {status !== undefined && (
                          orders.length > 0 ? orders.map((order, index) => (
                              <OrderCardDelivery
                                  key={index}
                                  order={order}
                                  status={order.status_relais}
                                  onClick={() => handleUpdateOrder(order)}
                              />
                          )) : <div className={'py-10 text-center w-full'}>Orders not found...</div>
                      )}
                    </div>


                  </div>
                </div>
                {order != null && <DetailOrderDelivery clickStatusOrder={changeStatusOrder} order={order} status={order.status_relais} />}
              </div>

          )
      }
       </>
  );
};

export default Delivery;

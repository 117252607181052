import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import UserOne from '../../assets/user.webp';
import { ToastSweet } from '../Loading';

const DropdownUser = ({onLogout}) => {
  DropdownUser.propTypes = {
    onLogout: PropTypes.func.isRequired,
  };

  const history = useNavigate();
  const handleLogoutClick = () => {
    onLogout();
    ToastSweet.fire({
      icon: "success",
      title: `A très bientôt.`,
    });
    history("/");
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative">
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4"
        to="#"
      >
        <img src={UserOne} alt="User" className={"h-12 w-12 rounded-full"} />
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-4 flex w-64 flex-col rounded-lg bg-white shadow-sm ${
          dropdownOpen === true ? "block" : "hidden"
        }`}
      >
        <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-5">
          <li>
            <Link
              to="/profile"
              className="flex items-center gap-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >
              Mon profil
            </Link>
          </li>
        </ul>
        <button
          onClick={handleLogoutClick}
          className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          Se déconnecter
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;

import {currency, formatDate} from "../../commun/helpers";

const OrderCardDelivery = ({status, order, onClick}) => {

    const statusOrder = (type = 'text') => {
        if(type === 'bg')
            return status === '1' ? 'bg-blue-600' : 'bg-green-400';
        if(type === 'text')
            return status === '1' ? 'text-blue-600' : 'text-green-400';
        if(type === 'status')
            return status === '1' ? 'En route' : 'Réçu';


    }

    return (
        <>
            <div onClick={() => onClick()} className="flex flex-1 flex-col cursor-pointer p-1 rounded-xl bg-white shadow w-full">

                <div className="md:flex justify-between py-3 md:py-1 mx-1">
                    <div className="flex flex-1 gap-6">
                        <div className={'w-32 h-32 '}>
                            <img src={order.produit.photoprods[0].photo} alt="product" className={'w-32 h-32 object-cover rounded-xl'}/>
                        </div>
                        <div className="flex flex-col justify-between py-2">
                            <div>
                                <div className="font-medium text-lg md:text-xl two__line__container">
                                    {order.produit.nom}
                                </div>
                                <div className="font-bold text-sm md:text-2xl">
                                    {currency(order.total)}
                                </div>
                            </div>
                            <div>Commission reçu {currency(order.commission_ambassador)}</div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between py-1 px-2">
                        <div className="text-sm text-end">{formatDate(order.created_at)}</div>
                        <div>
                            <div
                                className={`py-2 px-7 font-bold rounded-xl ${statusOrder('text')}`}
                            >
                                {statusOrder('status')}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default OrderCardDelivery;
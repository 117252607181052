import {useState} from "react";

const Select = ({icon : Icon, placeholder="", id, label = null, data = [], onChange}) => {

    const [focus, setFocus] = useState("gray")

    const handleChangeColor = (state) => {
        if(state === 'focus')
            setFocus("blue");
        else
            setFocus("gray");

    }

    return (
        <>
            {
                label &&
                <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
                    {label}</label>
            }

            <div className="relative mb-0">
                {
                    Icon && <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                        <Icon
                            size={30}
                            color={focus}
                        />
                    </div>
                }

                <select
                    id={id}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={() => handleChangeColor("focus")}
                    onBlur={() => handleChangeColor("blur")}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ${Icon ? 'ps-14' : ''}`}
                >
                    {
                        data.map((item, index) => (
                            <option key={index} value={item.id}>{item.nom}</option>
                        ))
                    }
                </select>
            </div>
        </>
    )
}

export default Select;

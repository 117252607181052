import Api from "../const/api";
import axios from "../const/config";

export default class OrderService {
  async orders() {
    try {
      const res = await axios.get(Api.orders);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async in_progress() {
    try {
      const res = await axios.get(Api.orders_in_progress);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async received() {
    try {
      const res = await axios.get(Api.orders_received);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async success() {
    try {
      const res = await axios.get(Api.orders_success);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async cancel() {
    try {
      const res = await axios.get(Api.orders_cancel);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async change_status(_id, motif) {
    try {
      const res = await axios.put(`${Api.order_status}${_id}`, {motif});
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

}



const CardLogin = ({ className, children }) => {
    return (
        <div className={`rounded-2xl shadow-md p-12 ${className}`}>
            {children}
        </div>
    )
}

export default CardLogin;
import {useEffect} from "react";
import img from "../assets/assistance_service.png";
import Sidebar2 from "../components/sidebar2/Sidebar2";
import {FaWhatsapp} from "react-icons/fa6";
import {IoMdCall} from "react-icons/io";
import {IoMailUnread} from "react-icons/io5";

const AssistantService = ({title}) => {

    useEffect(() => {
        document.title = `${title} - Ambassadeur`;
    }, [title]);

    return (
        <>
            <div className="flex gap-4 lg:w-[54vw]">
                <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
                    <div
                        className={
                            "rounded-2xl bg-white shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
                        }
                    >
                        <div className={"shadow-md p-5 text-xl font-medium bg-white rounded-t-2xl px-8 "}>
                            SERVICE D&apos;ASSISTANCE
                        </div>
                        <div
                            className={
                                "border-gray-100  flex flex-col space-y-5 justify-center px-5 "
                            }
                        >
                            <div>
                                <img src={img} alt="assistance_service" />
                            </div>
                            <div className={'grid grid-cols-3 gap-10 p-10'}>
                                <div className={'flex justify-between p-2 rounded shadow'}>
                                    <FaWhatsapp size={30} className={'text-green-500'} />
                                    <div>Ecris à un agent...</div>
                                    <div></div>
                                </div>
                                <div className={'flex justify-between p-2 rounded shadow'}>
                                    <IoMdCall size={30} className={'text-lime-500'} />
                                    <div>Appellez directement</div>
                                    <div></div>
                                </div>
                                <div className={'flex justify-between p-2 rounded shadow'}>
                                    <IoMailUnread size={30} className={'text-yellow-400'} />
                                    <div>Envoyer un e-mail</div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Sidebar2 />
            </div>
        </>
    )
}

 export default AssistantService;
import {useEffect, useState} from "react";
import Wallet from "../../components/wallet/Wallet";
import RecruiterWalletCard from "../../components/recruiter/RecruiterWalletCard";
import Sidebar2 from "../../components/sidebar2/Sidebar2";
import {UserService} from "../../services/UserService";
import {initWallet} from "../relay_point/WalletRelayPoint";

const WalletRecruiterRegistration = ({ title }) => {

  const [wallet, setWallet] = useState(initWallet);

  useEffect(() => {
    const _userService = new UserService();

    const data = async () => {
      const res = await _userService.wallet();

      if(res.status)
        setWallet(res.res.data.wallet);
    }

    data();
  },[])

  useEffect(() => {
    document.title = `${title} - Ambassadeur`;

  }, [title]);


  const data =  [
      //{ fullname: 'TRAORE Lamine', createdAt: '14/12/2023 à 12:40', city: 'Abidjan' },
      //{ fullname: 'Toure Stephane', createdAt: '02/12/2023 à 15:56', city: 'Bouaké' },
  ];

  return <>
    <div className="flex gap-4 lg:w-[54vw]">
      <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
        <Wallet wallet={wallet} className={'rounded-b-none'}/>
        <div className={'bg-white pb-1 rounded-xl'}>
          <div
              className={'shadow-md flex gap-20 items-end  bg-white rounded-t-2xl px-14 text-[12px] lg:text-lg'}>
            {['Réçu'].map((label, index) => (
                <div
                    key={index}
                    className={`py-4 text-xl cursor-pointer bg-gray-100 mt-2 px-10 rounded-t-3xl shadow-none text-blue-500 font-semibold`}
                >
                  {label}
                </div>
            ))}
          </div>
          <div className={'border-gray-100 m-2 bg-gray-100 rounded-lg  flex flex-col space-y-3 justify-center p-3'}>
            {data.map((recruiter, index) => (
                <RecruiterWalletCard
                    key={index}
                    fullname={recruiter.fullname}
                    createdAt={recruiter.createdAt}
                    city={recruiter.city}
                />
              ))
            }
          </div>
        </div>
      </div>
      <Sidebar2 />
    </div>
  </>;
};

export default WalletRecruiterRegistration;


export const currencyNoDevice = (number) => {
  return (
    Number(number).toLocaleString("fr-FR", { maximumFractionDigits: 0 })
   );
};

export const currency = (number) => {
  return (
    Number(number).toLocaleString("fr-FR", { maximumFractionDigits: 0 }) +
    " F CFA"
  );
};

export const formatDate = (date) => {
  const dateObj = new Date(date);

  const jour = dateObj.getDate();
  const mois = dateObj.getMonth() + 1;
  const annee = dateObj.getFullYear();

  const dateFormatee = `${(jour < 10 ? "0" : "") + jour}/${
    (mois < 10 ? "0" : "") + mois
  }/${annee}`;

  return `${dateFormatee}`;
  
};

export const formatHour = (date) => {
  const dateObj = new Date(date);

  const heure = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const heureFormatee = `${(heure < 10 ? "0" : "") + heure}:${
    (minutes < 10 ? "0" : "") + minutes
  }`;

  return `${heureFormatee}`;
};

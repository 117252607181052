import badge from '../../assets/badge.png';
import no_badge from '../../assets/no_badge.png';
import {IoIosCall} from "react-icons/io";
import { imgUserDefault } from '../../const/const';
import { formatDate, formatHour } from '../../commun/helpers';

const RecruiterCard = ({user}) => {
    return (
      <>
        <div
          className={
            "lg:flex cursor-pointer justify-between items-center flex-1 bg-white rounded-lg py-2"
          }
        >
          <div className={"flex-1 lg:flex"}>
            <div
              className={
                "flex flex-1 justify-center lg:justify-start items-center gap-4 px-2 border-r"
              }
            >
              <div>
                <img
                  src={user.photo ?? imgUserDefault}
                  alt="user"
                  className={"w-20 h-20 border-2 border-gray-50 rounded-full"}
                />
              </div>
              <div className={"flex-1 flex flex-col"}>
                <div className={"text-xl font-[600]"}>
                  {user.nom} {user.prenom}
                </div>
                <div className={"text-gray-600 text-sm"}>
                  {user.ville.pays.nom}, {user.ville.nom}{" "}
                </div>
                <div
                  className={`${
                    user.status_badge !== "0"
                      ? "text-purple-600"
                      : "text-rose-600"
                  } text-[10px]`}
                >
                  {user.status_badge !== "0"
                    ? "Badge certifié"
                    : "Badge non certifié"}
                </div>
              </div>
            </div>
            <div
              className={
                "flex flex-col justify-center text-sm text-gray-600 px-6 border-r"
              }
            >
              <div>Inscrit le {formatDate(user.created_at)}</div>
              <div>{formatHour(user.created_at)}</div>
            </div>
            <div
              className={
                "flex flex-col justify-center text-sm text-gray-600 px-6"
              }
            >
              <div>
                <IoIosCall />
              </div>
              <div>{user.contact}</div>
            </div>
          </div>
          {user.status_badge !== "0" ? (
            <div className={"flex flex-col items-center px-2 border-l w-20"}>
              <img
                src={badge}
                alt="user"
                className={"w-10 h-10 rounded-full"}
              />
              <div className={"text-[12px]"}>{user.badgeDate}</div>
            </div>
          ) : (
            <div
              className={"flex justify-center items-center px-2 border-l w-20"}
            >
              <img
                src={no_badge}
                alt="user"
                className={"w-10 h-10 rounded-full"}
              />
            </div>
          )}
        </div>
      </>
    );
}
export default RecruiterCard;
import { useEffect, useState } from "react";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import ButtonComponent from "../../components/Button";
import Select from "../../components/Select";

import { GoPerson } from "react-icons/go";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { Button } from "flowbite-react";
import { FaRegFlag } from "react-icons/fa";
import { GiModernCity } from "react-icons/gi";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr";
import { AiFillLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { LiaUnlockAltSolid } from "react-icons/lia";
import OtpInput from "react18-input-otp";
import Sidebar2 from "../../components/sidebar2/Sidebar2";
import { CountryService } from "../../services/CountryService";
import LoadingSpinner, { ToastSweet } from "../../components/Loading";
import { RecruiterService } from "../../services/RecruiterService";
import Swal from "sweetalert2";

const AddRecruiteur = ({ title, user }) => {
  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
    countryController();
  }, [title]);

  //import service
  const _recruiterService = new RecruiterService();
  const _countryService = new CountryService();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [modalVerification, setModalVerification] = useState(false);
  const [otp, setOtp] = useState("");
  const [modalSend, setModalSend] = useState(false);

  //data
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");

  const handleCountryChange = (value) => {
    const country = countries.find((country) => country.id === Number(value));
    setCities(country.villes);
    setCity(cities[0].id);
  };

  const handleCityChange = (value) => {
    setCity(value);
    console.log(city);
  };

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const goToRecruiters = () => {
    setModalSend(false);
    navigate("/ambassador/recruiter_registration/recruiters");
  };

  const countryController = async () => {
    const response = await _countryService.countries();

    if (response.status) {
      setCountries(response.data.countries);

      setCities(response.data.countries[0].villes);
      setCity(response.data.countries[0].villes[0].id);

      setLoading(false);
    } else console.error(response);
  };

  const handleOpenModal = async () => {
    if (phone && firstName && lastName && email && city) {
      const formData = new FormData();

      formData.append("email", email);
      formData.append("phone_number", phone);

      await sendOTPController(formData);
    } else {
      Swal.fire({
        title: "Erreur",
        text: "Veuillez renseigner le formulaire svp!",
        icon: "error",
      });
    }
  };

  const sendOTPController = async (formData) => {
    setLoading(true);
    const response = await _recruiterService.sendOTP(formData);
    setLoading(false);
    setOtp("");
    if (response.status) {
      setModalVerification(true);
      ToastSweet.fire({
        icon: "success",
        title: response.res.data.message ?? "",
      });
    } else {
      Swal.fire({
        title: "Erreur",
        text: response.e.response.data.message,
        icon: "error",
      });
    }
  };

  const handleVerification = async () => {
    const formData = new FormData();

    formData.append("email", email);
    formData.append("phone_number", phone);
    formData.append("code_verified", otp);
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("city", city);

    await storeController(formData);
  };

  const storeController = async (formData) => {
    setLoading(true);
    const response = await _recruiterService.store(formData);
    setLoading(false);
    if (response.status) {
      setModalVerification(false);
      setModalSend(true);
      ToastSweet.fire({
        icon: "success",
        title: response.res.data.message ?? "",
      });
    } else {
      Swal.fire({
        title: "Erreur",
        text: response.e.response.data.message,
        icon: "error",
      });
    }
  };

  const Verification = () => {
    return (
      <>
        <div className={"flex flex-col items-center justify-center space-y-10"}>
          <LiaUnlockAltSolid size={80} />
          <div className={"flex flex-col "}>
            <div className={"lg:w-[20vw]"}>
              <span>
                Saisissez le code de vérification envoyé sur votre boite mail :
              </span>
              <span className={"text-blue-600 mx-2"}>{email}</span>
            </div>
          </div>
          <div className={"flex flex-col "}>
            <div className={"flex flex-col justify-center items-center"}>
              <OtpInput
                id="myInput"
                placeholder="     "
                value={otp}
                onChange={handleChange}
                numInputs={5}
                isSuccessed={false}
                errorStyle="error"
                successStyle="success"
                inputStyle={"text-3xl card__code__opt"}
                containerStyle={"p-2"}
                separator={<span className={"mx-2"}> </span>}
                separateAfter={1}
                shouldAutoFocus
                // onSubmit={() => handleSubmit}
              />
            </div>
            <div
              className={
                "flex flex-col gap-3 justify-start text-left font-light text-sm pt-10"
              }
            >
              <div
                onClick={() => handleOpenModal()}
                className=" cursor-pointer"
              >
                Renvoyez le code
              </div>
              <div
                className={"cursor-pointer"}
                onClick={() => setModalVerification(false)}
              >
                Modifier le Email
              </div>
            </div>
          </div>
          <Button
            onClick={handleVerification}
            color={"yellow"}
            className={
              "bg__color__yellow text-white hover:text-black py-2 text-xl font-bold"
            }
          >
            CONFIRMER
          </Button>
        </div>
      </>
    );
  };

  const Check = () => {
    return (
      <div className={"flex flex-col justify-between h-72 "}>
        <div className={"flex justify-center pt-10"}>
          <AiFillLike size={60} color={"blue"} />
        </div>
        <div className={"text-center text-sm"}>
          <div className={"text-blue-500 uppercase text-base"}>
            {firstName} {lastName}
          </div>
          <div className={"pb-5"}>a été ajouté avec succès</div>
          <div className={""}>
            Des accès temploraire ont été envoyé par le mail du récruteur
          </div>
        </div>
        <div className={"flex justify-center px-10"}>
          <div
            onClick={goToRecruiters}
            className={
              "cursor-pointer rounded text-center text-white text-lg bg-gradient-to-r from-purple-700 to-rose-700 py-1 font-bold w-full "
            }
          >
            OK
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="flex gap-4 lg:w-[54vw]">
          <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
            <div
              className={
                "rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
              }
            >
              <div
                className={
                  "shadow-md flex justify-between items-center  bg-white rounded-t-2xl px-3"
                }
              >
                <div className={"p-5 text-lg"}>AJOUT DE RECRUTEUR</div>
              </div>
              <div
                className={
                  "border-gray-100 flex flex-col space-y-5 justify-center"
                }
              >
                <div className={"bg-white m-1 rounded-b-2xl"}>
                  <div className={"flex flex-col items-center justify-center"}>
                    <div className={"lg:w-96 text-center py-10"}>
                      Ajouter rapidement un recruteur et profité des avantages
                      de chez Daymond
                    </div>
                  </div>
                  <div
                    className={
                      "md:grid grid-cols-2 gap-10 space-y-5 lg:space-y-0 p-10"
                    }
                  >
                    <div>
                      <Input
                        id={"firstname"}
                        placeholder={"Nom"}
                        type={"text"}
                        icon={GoPerson}
                        value={firstName}
                        onChange={(value) => setFirstName(value)}
                      />
                    </div>
                    <div>
                      <Input
                        id={"lastname"}
                        placeholder={"Prénoms"}
                        type={"text"}
                        icon={GoPerson}
                        value={lastName}
                        onChange={(value) => setLastName(value)}
                      />
                    </div>
                    <div>
                      <Select
                        id={"country"}
                        placeholder={"Selectionner le pays"}
                        icon={FaRegFlag}
                        data={countries}
                        onChange={handleCountryChange}
                      />
                    </div>
                    <div>
                      <Select
                        id={"city"}
                        placeholder={"Selectionner la ville"}
                        icon={GiModernCity}
                        data={cities}
                        value={city}
                        onChange={handleCityChange}
                      />
                    </div>
                    <div>
                      <PhoneInput
                        international
                        labels={fr}
                        defaultCountry="CI"
                        value={phone}
                        onChange={setPhone}
                      />
                    </div>
                    <div>
                      <Input
                        id={"email"}
                        placeholder={"Email"}
                        type={"email"}
                        icon={MdOutlineMarkEmailRead}
                        value={email}
                        onChange={(value) => setEmail(value)}
                      />
                    </div>
                  </div>
                  <div className={"flex justify-center p-10"}>
                    <ButtonComponent
                      type={"button"}
                      onClick={() => handleOpenModal()}
                      className={"w-full lg:w-96 bg-fuchsia-900"}
                      label={"ENREGISTRER"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Sidebar2 />
        </div>
      )}
      <Modal
        className={""}
        header={true}
        textHeader={"Confirmation du compte"}
        open={modalVerification}
        size={"xl"}
        setOpen={setModalVerification}
      >
        <Verification />
      </Modal>
      <Modal
        className={"rounded-lg border-[3px] border-blue-500"}
        open={modalSend}
        setOpen={setModalSend}
        size={"sm"}
      >
        <Check />
      </Modal>
    </>
  );
};

export default AddRecruiteur;

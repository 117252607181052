
const TitleSidebar2 = ({title}) => {
    return <>
        <div className={'p-5 flex gap-5 items-center'}>
            <div>{title}</div>
            <div className={'flex-1 w-full h-[2px] bg-gray-200'}></div>
        </div>
    </>
}

export default TitleSidebar2;
import { Link, NavLink, useLocation } from "react-router-dom";
import DropdownNotification from './DropdownNotification';
import DropdownUser from './DropdownUser';
import LogoIcon from '../../assets/ambassador.png';
import PropTypes from "prop-types";
import { FiMapPin } from "react-icons/fi";
import { GrSearchAdvanced } from "react-icons/gr";
import { BsMinecartLoaded } from "react-icons/bs";
import { RiUserSearchLine } from "react-icons/ri";

const Header = ({ sidebarOpen, link, setSidebarOpen, onLogout }) => {

  Header.propTypes = {
    onLogout: PropTypes.func.isRequired,
  };

  const location = useLocation();
  const { pathname } = location;

  return (
    <header className="sticky top-0 z-50 flex w-full drop-shadow-1 bg-white">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              setSidebarOpen(!sidebarOpen);
            }}
            className="z-30 block rounded-sm border-none bg-white mb-4 shadow-sm lg:hidden"
          >
            <span className="relative block h-5.5 w-5 cursor-pointer">
              <span className="du-block absolute right-0 h-full w-full">
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out  ${
                    !sidebarOpen && "!w-full delay-300"
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out  ${
                    !sidebarOpen && "delay-400 !w-full"
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out ${
                    !sidebarOpen && "!w-full delay-500"
                  }`}
                ></span>
              </span>

              <span className="absolute right-0 h-full w-full rotate-45">
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out ${
                    !sidebarOpen && "!h-0 !delay-[0]"
                  }`}
                ></span>
                <span
                  className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "!h-0 !delay-200"
                  }`}
                ></span>
              </span>
            </span>
          </button>
          {/* <!-- Hamburger Toggle BTN --> */}

          <Link className="block flex-shrink-0 lg:hidden" to="/">
            <img src={LogoIcon} className={"w-36"} alt="Logo" />
          </Link>
        </div>

        <div className="flex flex-1 md:mx-10 justify-center">
          <div className="hidden md:block flex-1">
            <div className="flex rounded-full shadow-lg py-3">
              <NavLink
                to="relay_point/delivery"
                onClick={() => link(1)}
                className={`flex-1 border-r flex flex-col items-center justify-center space-y-1 ${
                  pathname.includes("relay_point") && "text__color__yellow"
                }`}
              >
                <FiMapPin size={25} />
                <div>Point relais</div>
              </NavLink>
              <NavLink
                to="recruiter_registration/recruiters"
                onClick={() => link(2)}
                className={`flex-1 border-r flex flex-col items-center justify-center space-y-1 ${
                  pathname.includes("recruiter_registration") &&
                  "text__color__yellow"
                }`}
              >
                <GrSearchAdvanced size={25} />
                <div>Inscription recruteur</div>
              </NavLink>
              <NavLink
                to="seller_account/orders"
                onClick={() => link(3)}
                className={`flex-1 border-r flex flex-col items-center justify-center space-y-1 ${
                  pathname.includes("seller_account") && "text__color__yellow"
                }`}
              >
                <BsMinecartLoaded size={25} />
                <div>Compte vendeur</div>
              </NavLink>
              <NavLink
                to="recruiter_account/sellers-list"
                onClick={() => link(4)}
                className={`flex-1 border-r flex flex-col items-center justify-center space-y-1 ${
                  pathname.includes("recruiter_account") &&
                  "text__color__yellow"
                }`}
              >
                <RiUserSearchLine size={25} />
                <div>Compte recruteur</div>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-items-center gap-7 2xsm:gap-7">
          {/* <!-- Notification Menu Area --> */}
          <DropdownNotification />
          {/* <!-- Notification Menu Area --> */}

          {/* <!-- User Area --> */}
          <DropdownUser onLogout={onLogout} />
          {/* <!-- User Area --> */}
        </div>
      </div>
    </header>
  );
};

export default Header;

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoNotificationsOutline} from "react-icons/io5";

const DropdownNotification = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notifying, setNotifying] = useState(true);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative">
            <Link
                ref={trigger}
                onClick={() => {
                    setNotifying(false);
                    setDropdownOpen(!dropdownOpen);
                }}
                to="#"
                className="relative flex h-8 w-8 items-center justify-center bg-gray hover:text-primary"
            >
        <span
            className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1 ${
                notifying === false ? 'hidden' : 'inline'
            }`}
        >
          <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
        </span>

                <IoNotificationsOutline className={'hover:text-yellow-500'} size={30} />
            </Link>

            <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`absolute -right-27 mt-3 flex h-90 w-75 flex-col rounded-lg py-3 bg-white shadow-sm sm:right-0 sm:w-80 ${
                    dropdownOpen === true ? 'block' : 'hidden'
                }`}
            >
                <div className="px-5 py-3">
                    <h5 className="text-base font-medium text-bodydark2">Notification</h5>
                </div>

                <ul className="flex h-auto flex-col overflow-y-auto">
                    <li>
                        <Link
                            className="flex flex-col gap-2.5 border-t border-stroke px-5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
                            to="#"
                        >
                            <p className="text-sm">
                                <span className="text-black dark:text-white">
                                  Edit your information in a swipe
                                </span>
                                Sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim.
                            </p>

                            <p className="text-xs">12 May, 2025</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DropdownNotification;

import Api from "../const/api";
import axios from "../const/config";

export class UserService {
  async detail() {
    try {
      const res = await axios.get(Api.detail);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async wallet() {
    try {
      const res = await axios.get(Api.wallet);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async transactionCommissionRecruiter() {
    try {
      const res = await axios.get(Api.wallet);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async logout() {
    try {
      const res = await axios.post(Api.logout);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }
}

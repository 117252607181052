
const Button = ({className, type = 'button', label, onClick}) => {
    return (
        <>
            <button
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline ${className}`}
                type={type}
                onClick={onClick}
            >
                {label}
            </button>
        </>
    )
}

export default Button;
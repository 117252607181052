import {currency, formatDate} from "../../commun/helpers";

const DetailOrder = ({order}) => {

    const statusOrder = (type = 'text') => {
        if(type === 'bg')
            return order.status === 3 ? 'bg-green-600' : 'bg-red-400';
        if(type === 'text')
            return order.status === 3 ? 'text-green-600' : 'text-red-400';
        if(type === 'header')
            return order.status === 3 ? 'Commande validée' : 'Commande annulée';
        if(type === 'status')
            return order.status === 3 ? 'Validée' : 'Anullée';
    }

    return (
        <>
            <div className="w-[33vw] h-[85vh] pt-5 xl:pt-0 rounded-xl xl:block pr-9">
                <div className="flex gap-4">
                    <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
                        <div
                            className={'rounded-2xl pb-1 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'}>
                            <div className={`shadow-md flex justify-between items-center ${statusOrder('bg')} text-white  rounded-t-2xl p-3 px-5`}>
                                <div>
                                    <div className={'text-xl font-medium'}>{statusOrder('header')}</div>
                                    <div>{formatDate(order.created_at)}</div>
                                </div>
                                <div>
                                    <div>{order.reference}</div>
                                </div>
                            </div>
                            <div
                                className={'border-gray-100 lg:min-h-[75vh] m-1 bg-white rounded-2xl flex flex-col justify-between p-2'}>
                                <div className={'flex flex-col space-y-5'}>
                                    <div className="flex flex-1 flex-col cursor-pointer rounded-xl bg-white shadow">
                                        <div className="md:flex justify-between relative">
                                            <div
                                                className="absolute font-medium top-0 right-0 p-1 px-4 text-sm bg__color__yellow rounded-l-lg rounded-tr-xl"
                                            >
                                                {order.produit.etat.nom}
                                            </div>
                                            <div className="flex flex-1 gap-6">
                                                <div className={'w-36 h-32 '}>
                                                    <img src={order.produit.photoprods[0].photo} alt="produit"
                                                         className={'w-32 h-32 object-cover rounded-xl rounded-r-none'}/>
                                                </div>
                                                <div className="flex flex-col justify-between py-2">
                                                    <div className={'pt-4'}>
                                                        <div
                                                            className="font-medium text-lg md:text-xl two__line__container">
                                                            {order.produit.nom}
                                                        </div>
                                                        <div
                                                            className="font-bold text-blue-500 text-sm md:text-2xl">
                                                            {currency(order.prix_proposition)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                order.produit.price_normal && (
                                                    <div className="flex flex-col justify-end py-1 px-2">
                                                        <div
                                                            className="text-sm text-end">{order.produit.prix_normal}</div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                    <div className={'space-y-2 px-10 pb-6 border-b'}>
                                        <div className={'flex justify-between'}>
                                            <div>Quatité</div>
                                            <div className={'font-medium'}>{order.qte} pièce(s)</div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Prix unitaire</div>
                                            <div
                                                className={'font-medium'}>{currency(order.prix_proposition)}
                                            </div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Prix d&apos;achat</div>
                                            <div
                                                className={'font-medium'}>{currency(order.prix_proposition * order.qte)}
                                            </div>
                                        </div>
                                        <div className={'flex justify-between'}>
                                            <div>Livraison {order.lieu_livraison}</div>
                                            <div
                                                className={'font-medium'}>{currency(order.frais_livraison * order.qte)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'space-y-2 px-10 pb-4'}>
                                        <div className={'flex justify-between font-bold text-xl'}>
                                            <div>TOTAL A PAYER</div>
                                            <div
                                                className={'text__color__yellow'}>{currency(order.total)}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={'text-center'}>Détails de la livraison</div>
                                        <div className={'flex justify-center py-2 pt-2'}>
                                            <div className={' w-10 h-[2px] bg-gray-200 mt-2'}></div>
                                        </div>
                                        <div className={'space-y-3 px-10 py-3 pb-6'}>
                                            <div className={'flex'}>
                                                <div className={'flex-1 space-y-3'}>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Nom</div>
                                                        <div>{order.nom}</div>
                                                    </div>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Contact</div>
                                                        <div>{order.contact1}</div>
                                                    </div>
                                                    <div className={'flex-1 flex flex-col'}>
                                                        <div className={'font-medium'}>Date de livraison</div>
                                                        <div>{formatDate(order.date_livraison)}</div>
                                                    </div>
                                                </div>
                                                <div className={'space-y-3'}>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Livraison</div>
                                                        <div>{order.lieu_livraison}</div>
                                                    </div>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Contact du vendeur</div>
                                                        <div>{order.vendeur.contact}</div>
                                                    </div>
                                                    <div className={'flex flex-col'}>
                                                        <div className={'font-medium'}>Heure de livraison</div>
                                                        <div>{order.heure_livraison}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DetailOrder;
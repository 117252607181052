import {currency} from "../../commun/helpers";

const OrderWalletCard = ({typeTransaction, createdAt, amount, image}) => {
    return (
        <>
            <div className={'lg:flex justify-between items-center shadow flex-1 bg-white rounded-lg py-2'}>
                <div className={'flex-1 lg:flex '}>
                    <div className={'flex flex-1 justify-center lg:justify-start items-center gap-4 px-2 '}>
                        <div>
                            <img src={image} alt="user" className={'w-20 h-20 object-cover'}/>
                        </div>
                        <div className={'flex-1 flex flex-col'}>
                            <div className={'text-xl font-[600]'}>{typeTransaction}</div>
                            <div className={'text-sm'}>{createdAt}</div>

                        </div>
                    </div>

                </div>
                <div className={'flex flex-col items-center px-6 lg:px-12 text-blue-500 text-[20px] font-bold'}>
                    {currency(amount)}
                </div>
            </div>
        </>
    )
}

export default OrderWalletCard;
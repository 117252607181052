import { useEffect } from "react";
import Maintenance from "../Maintenance";

const Wallet = ({ title }) => {
    useEffect(() => {
        document.title = `${title} - Ambassadeur`;
    }, [title]);

    return <>
        <Maintenance title={'Page de maintenant'} />
    </>;
};

export default Wallet;

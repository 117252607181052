import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import AssistantService from "../pages/AssistantService";
import Helpers from "../pages/Helpers";
import ProfilManager from "../pages/ProfilManager";
import ProfilCompany from "../pages/ProfilCompany";
import Maintenance from "../pages/Maintenance";
import RecruiterRegistration from "./dashboard/RecruiterRegistration";
import RelayPoint from "./dashboard/RelayPoint";
import AccountSeller from "./dashboard/AccountSeller";
import AccountRecruiter from "./dashboard/AccountRecruiter";
import { RouteAttachSidebar } from "../commun/route-attach-sidebar";
import PropTypes from "prop-types";
import { UserService } from "../services/UserService";
import { ToastSweet } from "../components/Loading";
import Loading from "../loading/Loading";

const Dashboard = ({ handleLogout }) => {
  const _userService = new UserService();
  const _history = useNavigate();

  Dashboard.prototypes = {
    handleLogout: PropTypes.func.isRequired,
  };

  useEffect(() => {
     detailController();
  },[]);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const detailController = async () => {
    const response = await _userService.detail();
    if (response.status) {
      localStorage.setItem("data", JSON.stringify(response.res.data.user));
      setUser(JSON.parse(localStorage.getItem("data")));
    setLoading(false);

    } else {
      if (response.e.response.status === 401) handleLogoutClick();
      else console.error(response);
    }
  };

  const handleLogoutClick = () => {
    handleLogout();
    ToastSweet.fire({
      icon: "success",
      title: `Veuillez vous connecter.`,
    });
    _history("/");
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const findCurrentRouteIndex = (routes) => {
    const currentPath = window.location.pathname.replace("/ambassador", "");
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].includes(currentPath)) {
        return i;
      }
    }
    return 0;
  };

  const currentIndex = findCurrentRouteIndex(RouteAttachSidebar);

  const [link, setLink] = useState(currentIndex);

  return (
    <>
      {loading ? (<Loading />) : (
        <div className="bg-gray-100">
          {/* <!-- ===== Page Wrapper Start ===== --> */}
          <div className="flex h-screen overflow-hidden">
            {/* <!-- ===== Sidebar Start ===== --> */}
            <Sidebar
              sidebarOpen={sidebarOpen}
              link={link}
              setLink={setLink}
              setSidebarOpen={setSidebarOpen}
            />
            {/* <!-- ===== Sidebar End ===== --> */}

            {/* <!-- ===== Content Area Start ===== --> */}
            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
              {/* <!-- ===== Header Start ===== --> */}
              <Header
                sidebarOpen={sidebarOpen}
                link={setLink}
                setSidebarOpen={setSidebarOpen}
                onLogout={handleLogout}
              />
              {/* <!-- ===== Header End ===== --> */}

              {/* <!-- ===== Main Content Start ===== --> */}
              <main className={"p-4"}>
                <div className={`flex-1 mx-auto w-[82vw] `}>
                  <Routes>
                    <Route
                      exact
                      path="/*"
                      element={<Home user={user} title={"Tableau de border"} />}
                    />
                    <Route
                      exact
                      path="/dashboard"
                      element={<Home user={user} title={"Tableau de bord"} />}
                    />

                    <Route
                      path="/relay_point/*"
                      element={<RelayPoint user={user} />}
                    />

                    <Route
                      path="/recruiter_registration/*"
                      element={<RecruiterRegistration user={user} />}
                    />

                    <Route
                      path="/seller_account/*"
                      element={<AccountSeller user={user} />}
                    />
                    <Route
                      path="/recruiter_account/*"
                      element={<AccountRecruiter user={user} />}
                    />
                    <Route
                      path="/seller_account"
                      element={<Maintenance title={"Page en maintenance"} />}
                    />
                    <Route
                      path="/recruiter_account"
                      element={<Maintenance title={"Page en maintenance"} />}
                    />

                    <Route
                      path="/assistant_service"
                      element={
                        <AssistantService
                          title={"Service d'assistance"}
                          user={user}
                        />
                      }
                    />
                    <Route
                      path="/helpers"
                      element={<Helpers title={"Centre d'aide"} user={user} />}
                    />
                    <Route
                      path="/profil_manager"
                      element={
                        <ProfilManager title={"Profil du gérant"} user={user} />
                      }
                    />
                    <Route
                      path="/profil_company"
                      element={
                        <ProfilCompany
                          title={"Profil de l'entreprise"}
                          user={user}
                        />
                      }
                    />
                  </Routes>
                </div>
              </main>
            </div>
            {/* <!-- ===== Content Area End ===== --> */}
          </div>
          {/* <!-- ===== Page Wrapper End ===== --> */}
        </div>
      )}
    </>
  );
};

export default Dashboard;

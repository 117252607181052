import { Routes, Route } from "react-router-dom";
import Wallet from "../../pages/seller_account/Wallet";
import Orders from "../../pages/seller_account/Orders";
import Barters from "../../pages/seller_account/Barters";
import Favorites from "../../pages/seller_account/Favorites";
import SalesLink from "../../pages/seller_account/SalesLink";

const AccountSeller = ({user}) => {

    return (
      <>
        <Routes>
          <Route
            path="/orders"
            element={<Orders user={user} title={"Mes commandes"} />}
          />
          <Route
            path="/barters"
            element={<Barters user={user} title={"Mes trocs"} />}
          />
          <Route
            path="/favorites"
            element={<Favorites user={user} title={"Mes favories"} />}
          />
          <Route
            path="/sales-link"
            element={<SalesLink title={"Liens de vente"} />}
          />
          <Route
            path="/wallet"
            element={<Wallet user={user} title={"Portefeuille"} />}
          />
        </Routes>
      </>
    );
}

export default AccountSeller;

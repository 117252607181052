import {useEffect, useState} from "react";
import Sidebar2 from "../components/sidebar2/Sidebar2";

const Helpers = ({title}) => {

    useEffect(() => {
        document.title = `${title} - Ambassadeur`;
    }, [title]);

    const [page, setPage] = useState(1);

    const changePAge = (index) => {
        setPage(index);
    }

    return (
        <>
            <div className="flex gap-4 lg:w-[54vw]">
                <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
                    <div
                        className={
                            "rounded-2xl bg-white shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
                        }
                    >
                        <div className={"shadow-md p-5 text-base  bg-white rounded-t-2xl px-14 flex justify-between"}>
                            <div
                                className={`cursor-pointer ${page === 1 ? 'text-blue-500 font-medium' : ''}`}
                                onClick={() => changePAge(1)}
                            >
                                QU&apos;EST CE C&apos;EST?
                            </div>
                            <div
                                className={`cursor-pointer ${page === 2 ? 'text-blue-500 font-medium' : ''}`}
                                onClick={() => changePAge(2)}
                            >
                                COMMENT CA FONCTIONNE
                            </div>
                            <div
                                className={`cursor-pointer ${page === 3 ? 'text-blue-500 font-medium' : ''}`}
                                onClick={() => changePAge(3)}
                            >
                                CONDITION D&apos;ITULISATION
                            </div>
                        </div>
                        <div
                            className={
                                "border-gray-100  flex flex-col space-y-5 justify-center px-5 py-6"
                            }
                        >
                            <div className={' shadow bg-white w-full h-48 rounded-xl p-10'}>
                                {
                                    page === 1 && (
                                        <div>
                                            1- Ambassadeur est une société d&apos;investissement qui vous donne la possiblité de multiplier...
                                        </div>
                                    )
                                }
                                {
                                    page === 2 && (
                                        <div>
                                            2- Ambassadeur est une société d&apos;investissement qui vous donne la possiblité de multiplier...
                                        </div>
                                    )
                                }
                                {
                                    page === 3 && (
                                        <div>
                                            3- Ambassadeur est une société d&apos;investissement qui vous donne la possiblité de multiplier...
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Sidebar2 />
            </div>

        </>
    )
}

export default Helpers;
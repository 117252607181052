import { Routes, Route } from "react-router-dom";
import Wallet from "../../pages/recruiter_registration/WalletRecruiterRegistration";
import Recruiters from "../../pages/recruiter_registration/Recruiters";
import AddRecruiteur from "../../pages/recruiter_registration/AddRecruiter";

const RelayPoint = ({user}) => {

    return (
      <>
        <Routes>
          <Route
            path="/recruiters"
            element={<Recruiters user={user} title={"Les récruteurs"} />}
          />
          <Route
            path="/add-recruiter"
            element={<AddRecruiteur user={user} title={"Ajout de récruteur"} />}
          />
          <Route
            path="/wallet"
            element={<Wallet user={user} title={"Portefeuille"} />}
          />
        </Routes>
      </>
    );
}

export default RelayPoint;

import Api from "../const/api";
import axios from "../const/config";

export class WalletService {

  async wallet() {
    try {
      const res = await axios.get(Api.wallet);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }


  async withdrawal(formData) {
    try {
      const res = await axios.post(Api.withdrawal, formData);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

}

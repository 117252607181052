import {useEffect} from "react";
import Maintenance from "../Maintenance";

const Stats = ({title}) => {
    useEffect(() => {
        document.title = `${title} - Ambassadeur`;
    }, [title]);
    return (
        <>
            <Maintenance />
        </>
    )
}
 export default Stats;
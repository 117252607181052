import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./layouts/Dashboard";
import { useEffect, useState } from "react";
import ProtectedRoute from "./pages/ProtectedRoute";
import { UserService } from "./services/UserService";

function App() {

  const _userService = new UserService();

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const handleLogout = () => {
    _userService.logout();
    localStorage.removeItem("token");
    
    setIsAuthenticated(false);
  };

  useEffect(() => {
    detailController();
  },[]);


  const detailController = async () => {
    const response = await _userService.detail();
    if (response.status) {
      localStorage.setItem("data", JSON.stringify(response.res.data.user));
    } else {
      if (response.e.response.status === 401) {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      }
      else console.error(response);
    }
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/ambassador/*"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Dashboard handleLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/ambassador" replace />
              ) : (
                <Login setIsAuthenticated={setIsAuthenticated} />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import { useEffect, useState } from "react";
import Wallet from "../../components/wallet/Wallet";
import Sidebar2 from "../../components/sidebar2/Sidebar2";
import OrderWalletCard from "../../components/order/OrderWalletCard";
import { UserService } from "../../services/UserService";
import {formatDate} from "../../commun/helpers";

export const initWallet = {
  somme: 0,
  transactions: [],
};

const WalletRelayPoint = ({ title, user }) => {
  const [wallet, setWallet] = useState(initWallet);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    const _userService = new UserService();

    const fetchData = async () => {
      const res = await _userService.wallet();

      if (res.status) setWallet(res.res.data.wallet);
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
  }, [title]);

  const handleChangeStatus = (code) => {
    setStatus(code);
  };

  // Filter transactions based on status
  const filteredTransactions = wallet.transactions.filter((transaction) => {
    if (status === 0) {
      return transaction.type === "commission"; // Received gains
    }
    if (status === 1) {
      return transaction.type === "retrait"; // Withdrawals
    }
    return false;
  });

  return (
      <>
        <div className="flex gap-4 lg:w-[54vw]">
          <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
            <Wallet className={"rounded-b-none"} wallet={wallet} />
            <div className={"bg-white pb-1 rounded-xl"}>
              <div
                  className={
                    "shadow-md flex gap-20 items-end  bg-white rounded-t-2xl px-14 text-[12px] lg:text-lg"
                  }
              >
                {["Réçu", "Retrait"].map((label, index) => (
                    <div
                        key={index}
                        onClick={() => handleChangeStatus(index)}
                        className={`py-4 text-xl cursor-pointer ${
                            status === index
                                ? "bg-gray-100 mt-2 px-10 rounded-t-3xl shadow-none text-blue-500 font-semibold"
                                : ""
                        }`}
                    >
                      {label}
                    </div>
                ))}
              </div>
              <div
                  className={
                    "border-gray-100 m-2 bg-gray-100 rounded-lg  flex flex-col space-y-3 justify-center p-3"
                  }
              >
                {filteredTransactions.map((transaction, index) => (
                    <OrderWalletCard
                        key={transaction.id}
                        typeTransaction={
                          status === 0 ? "Gain réçu" : "Rétrait"
                        }
                        createdAt={formatDate(transaction.created_at)}
                        amount={transaction.montant}
                        image={transaction.commande ? transaction.commande.produit.photoprods[0].photo : ''}
                    />
                ))}
              </div>
            </div>
          </div>
          <Sidebar2 />
        </div>
      </>
  );
};

export default WalletRelayPoint;

import axios from 'axios';
import Api from '../const/api';

export class CountryService {

  async countries() {
    return await axios.get(Api.countries);
  }

};





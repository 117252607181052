import {useEffect, useState} from "react";
import OrderCard from "../../components/order/OrderCard";
import DetailOrder from "../../components/order/DetailOrder";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../../components/Loading";

const Orders = ({ title, user }) => {
  const _orderService = new OrderService();

  const [ordersInProgress, setOrdersInProgress] = useState([]);
  const [ordersReceived, setOrdersReceived] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const dataSuccess = async () => {
    const res = await _orderService.success();
    if(res.status) {
      await setOrdersInProgress(res.res.data.orders);
      await setOrders(res.res.data.orders)
      if(res.res.data.orders.length > 0 && order == null)
        await setOrder(res.res.data.orders[0]);
    } else
      console.error(res.e);

  }

  const dataCancel = async (loa = false) => {
    const res = await _orderService.cancel();
    if(res.status) {
      await setOrdersReceived(res.res.data.orders);
      if(loa)
        await setOrders(res.res.data.orders);
      else if(res.res.data.orders.length > 0 && order == null)
      {
        await setOrders(res.res.data.orders);
        await setOrder(res.res.data.orders[0]);
        await setStatus(1);
      }
    } else
      console.error(res.e);
    setLoading(false);

  }

  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
  }, [title]);

  useEffect(() => {
    const init = async () => {
      await dataSuccess();
      await dataCancel();
    }
    init();
  },[])

  const [status, setStatus] = useState(0);
  const [order, setOrder] = useState(null);

  const handleChangeStatus = (code) => {
    if(code === 0)
      setOrders(ordersInProgress);
    else
      setOrders(ordersReceived);
    setStatus(code);
  };

  const handleUpdateOrder = (card) => {
    setOrder(card);
  };

  return (
    <>
      { loading ? (
      <LoadingSpinner loading={loading} />
      ) :
      <div className="lg:flex w-[82vw] gap-4">
        <div className={`flex-1 mx-auto min-w-[49vw] space-y-5`}>
          <div
            className={`rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] ${
              !order ? "lg:w-[50vw]" : ""
            }`}
          >
            <div
              className={`shadow-md flex space-x-20 items-end  bg-white rounded-t-2xl px-10 text-[12px] lg:text-lg`}
            >
              {["Terminés", "Annulées"].map(
                (label, index) => (
                  <div
                    key={index}
                    onClick={() => handleChangeStatus(index)}
                    className={`py-5 cursor-pointer ${
                      status === index
                        ? "border-b-2 border-blue-500 text-blue-500 font-semibold"
                        : ""
                    }`}
                  >
                    {label}
                  </div>
                )
              )}
            </div>

            <div
              className={
                "border-gray-100 max-h-[77vh] flex flex-col space-y-3 items-start p-3 overflow-y-auto"
              }
            >
              {status !== undefined && (
                  orders.length > 0 ? orders.map((order, index) => (
                  <OrderCard
                    key={index}
                    order={order}
                    status={status}
                    onClick={() => handleUpdateOrder(order)}
                  />
                )): <div className={'py-10 text-center w-full'}>Orders not found...</div>)}
            </div>
          </div>
        </div>
        {order && <DetailOrder order={order}  />}
      </div>
      }
    </>
  );
};

export default Orders;

import {BsCalendar2Date} from "react-icons/bs";
import {TiPinOutline} from "react-icons/ti";

const AppointmentCardPin = () => {
    return (
        <>
            <div className="bg-white rounded-xl md:flex space-y-5 md:space-y-0 justify-between p-4 px-5 shadow relative">
                <div className="absolute top-0 right-0 flex px-2 ">
                    <div className="p-1"><TiPinOutline size={20}/></div>
                </div>
                <div className={'flex gap-6'}>
                    <BsCalendar2Date size={50} className={'text-blue-500'}/>
                    <div className={'flex flex-col gap-1'}>
                        <div className="">Rendez-vous prevue le</div>
                        <div className="text-blue-500">12/12/2024</div>
                    </div>
                </div>
                <div className="flex flex-col justify-end">
                    <div className={'text-blue-500 text-center md:text-left'}>07 04 05 11 52</div>
                </div>

            </div>
        </>
    )
}

export default AppointmentCardPin;

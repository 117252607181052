import OrderCardPin from "./OrderCardPin";
import AppointmentCardPin from "./AppointmentCardPin";

const Pin = () => {
    return (
        <>
            <div className={'rounded-2xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'}>
                <div className={'shadow-md p-5 bg-white rounded-t-2xl px-8'}>Epinglé</div>
                <div className={'border-gray-100  flex flex-col space-y-5 justify-center p-5'}>
                    <AppointmentCardPin />
                    <OrderCardPin />
                    <AppointmentCardPin />
                </div>
            </div>
        </>
    )
}

export default Pin;

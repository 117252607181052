import TitleSidebar2 from "./TitleSidebar2";
import ElementCardSidebar2 from "./ElementCardSidebar2";
import {products} from "../../data";

const Sidebar2 = () => {
    return <>
        <div className="w-[24vw] h-[84vh] bg-white rounded-xl  hidden xl:block xl:fixed right-5">
            <TitleSidebar2 title={'Point relais'} />
            <div className={'px-2'}>
                <div className={'flex flex-col gap-2 bg-stone-100 rounded-xl p-2'}>
                    {
                        products.map((product, index) => (
                            <ElementCardSidebar2 key={index} product={product} />
                        ))
                    }
                </div>
            </div>
        </div>
    </>
}

export default Sidebar2;
import {useEffect, useState} from "react";
import Wallet from "../components/wallet/Wallet";
import Pin from "../components/pin/Pin";
import Sidebar2 from "../components/sidebar2/Sidebar2";
import {UserService} from "../services/UserService";


const Home = ({ title, user }) => {

    const initWallet = {
        somme : 0,
        transactions : [],
    }
    const [wallet, setWallet] = useState(initWallet);

    useEffect(() => {
        const _userService = new UserService();

        const data = async () => {
            const res = await _userService.wallet();

            if(res.status)
                setWallet(res.res.data.wallet);
        }

        data();
    },[])

    useEffect(() => {
      document.title = `${title} - Ambassadeur`;
      // Toast.fire({
      //   icon: "success",
      //   title: "Vous êtes connecté",
      // });
    }, [title]);

    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: "bottom-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //         toast.onmouseenter = Swal.stopTimer;
    //         toast.onmouseleave = Swal.resumeTimer;
    //     }
    // });

    return (
      <>
        <div className="flex gap-4 lg:w-[54vw]">
          <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
            <Wallet wallet={wallet} />
            <Pin />
          </div>
          <Sidebar2 />
        </div>
      </>
    );
}

export default Home;
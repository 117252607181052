import logo from './../assets/icon.png';

const Header = () => {
    return (
        <>
            <section className=" font-poppins bg-white shadow-md sticky top-0 " style={{ zIndex:2, }}>
                <div className="px-4 mx-auto">
                    <nav className="relative flex items-center justify-between py-4 ">
                        <div className="text-xl font-semibold leading-none dark:text-gray-400">
                            <img src={logo} alt="icon" className='w-28'/>
                        </div>
                    </nav>
                </div>
            </section>
        </>
    )
}

export default Header;
export const RouteAttachSidebar = [
  [
    "/dashboard",
    "/profil_company",
    "/profil_manager",
    "/helpers",
    "/assistant_service",
  ],
  [
    "/relay_point/delivery",
    "/relay_point/orders",
    "/relay_point/add-order",
    "/relay_point/wallet",
  ],
  [
    "/recruiter_registration/recruiters",
    "/recruiter_registration/add-recruiter",
    "/recruiter_registration/wallet",
  ],
  [
    "/seller_account/orders",
    "/seller_account/orders",
    "/seller_account/favorites",
    "/seller_account/sales-link",
    "/seller_account/wallet",
  ],
  [
    "/recruiter_account/sellers-list",
    "/recruiter_account/add-seller",
    "/recruiter_account/statistic",
    "/recruiter_account/wallet",
  ],
];
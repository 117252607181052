import React from "react";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const ToastSweet = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

const LoadingSpinner = ({ loading }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        display: loading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFB5E338",
      }}
    >
      <ClipLoader
        color={"#000000"}
        loading={loading}
        css={override}
        size={150}
      />
    </div>
  );
};

export default LoadingSpinner;

import {useEffect, useState} from "react";
import {orders} from "../../data";
import DetailOrder from "../../components/order/DetailOrder";
import Input from "../../components/Input";
import {MdSearch} from "react-icons/md";
import OrderCard from "../../components/order/OrderCard";

const AddOrder = ({ title }) => {
  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
  }, [title]);

  const [status, setStatus] = useState(0);
  const [order, setOrder] = useState(orders[0]);
  const [orderFilter, setOrderFilter] = useState(orders);
  const [numberPhone, setNumberPhone] = useState('');

  const handleUpdateOrder = (card) => {
    setOrder(card);

    //pas de logique juste pour le push sur github
    setStatus(0);
  }

  const handleSearchOrder = (value) => {
    setNumberPhone(value);

    const filteredOrders = orders.filter((order) =>
        order.user.phone.includes(value)
    );

    setOrderFilter(filteredOrders);
  }


  return (
      <>
        <div className="lg:flex gap-4 h-[84vh]">
          <div className={`flex-1 mx-auto max-w-screen-2xl space-y-5`}>
              <div className="flex-1  mx-auto max-w-screen-2xl space-y-5">
                <div
                    className={'rounded-2xl pb-1 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'}>
                  <div className={'shadow-md flex justify-between items-center bg-white rounded-t-2xl px-3'}>
                    <div className={'p-5 text-base'}>AJOUTER</div>
                  </div>
                  <div
                      className={' pb-1 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'}>

                    <div className={'py-2 flex flex-row justify-center items-center gap-5 px-20'}>
                      <div className={'w-full'}>
                        <Input
                            id={'phone_number'}
                            placeholder={'Numéro du destinataire'}
                            className={''}
                            type={'search'}
                            value={numberPhone}
                            onChange={(value) => handleSearchOrder(value)}

                        />
                      </div>
                      <div className={'border border-transparent border__color__yellow rounded-xl p-2'}>
                        <MdSearch size={40} />
                      </div>
                    </div>
                  </div>
                  <div
                      className={'border-gray-100 p-2'}>
                    <div className={'border-gray-100 h-full overflow-y-auto  flex flex-col space-y-5 justify-center p-5'}>
                      {status !== undefined && orderFilter.map((order, index) => (
                          <OrderCard
                              key={index}
                              order={order}
                              status={status}
                              onClick={() => handleUpdateOrder(order)}
                          />
                      ))}
                    </div>
                  </div>
                </div>

              </div>

          </div>
          {
              order && (
                  <DetailOrder order={order}/>
              )

          }
        </div>
      </>
  );
};

export default AddOrder;

import {useEffect, useState} from "react";
import Sidebar2 from "../components/sidebar2/Sidebar2";
import LoadingSpinner from "../components/Loading";

const ProfilManager = ({title, user}) => {

    useEffect(() => {
        document.title = `${title} - Ambassadeur`;
    }, [title]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(false);
    }, []);

    return (
      <>
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className="flex gap-4  lg:w-[54vw]">
            <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
              <div
                className={
                  "rounded-2xl bg-white shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
                }
              >
                <div
                  className={
                    "shadow-md p-5 text-xl font-medium bg-white rounded-t-2xl px-8 "
                  }
                >
                  PROFIL DU GERANT
                </div>
                <div
                  className={
                    "border-gray-100  flex flex-col space-y-5 justify-center p-5 px-16 "
                  }
                >
                  <div
                    className={
                      "bg-rose-200 text-white rounded-lg text-3xl font-bold p-10 relative"
                    }
                  >
                    <div
                      className={
                        "text-white rounded-t-lg top-0 text-center text-3xl font-bold absolute py-10"
                      }
                    >
                      <img
                        src={user.ambassador.photo_gerant}
                        alt="img"
                        className={"w-32 h-32 rounded-full p-0.5 bg-pink-500"}
                      />
                    </div>
                    <div className={"pl-40"}>
                      <div className={"text-pink-600 font-normal"}>
                        ADMIN PRINCIPAL
                      </div>
                      <div className={"text-sm text-black "}>Accès total</div>
                    </div>
                  </div>
                  <div className={"lg:flex justify-around pt-10 "}>
                    <div>
                      <div className={"pb-5 border-b"}>
                        <div className={"text-2xl text-gray-600"}>Nom </div>
                        <div className={"text-2xl"}>
                          {user.ambassador.nom_gerant}
                        </div>
                      </div>
                      <div className={"py-5 border-b"}>
                        <div className={"text-2xl text-gray-600"}>Prénoms </div>
                        <div className={"text-2xl"}>
                          {user.ambassador.prenom_gerant}
                        </div>
                      </div>
                      <div className={"py-5 border-b"}>
                        <div className={"text-2xl text-gray-600"}>Contact </div>
                        <div className={"text-2xl"}>
                          
                          {user.ambassador.contact_gerant}
                        </div>
                      </div>
                      <div className={"py-5 border-b"}>
                        <div className={"text-2xl text-gray-600"}>N°CNI </div>
                        <div className={"text-2xl"}>
                          
                          {user.ambassador.numero_cni}
                        </div>
                      </div>
                      <div className={"py-5 border-b"}>
                        <div className={"text-2xl text-gray-600"}>
                          Nombre d&apos;employer
                        </div>
                        <div className={"text-2xl"}>
                          
                          {user.ambassador.nombre_employe}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={"flex flex-col py-5 px-5 border rounded-lg"}
                      >
                        <div className={"pb-2"}>
                          Photo CNI, Passport, attestion ou permis de <br />
                          conduit
                        </div>
                        <div className={"flex flex-col py-0 gap-5"}>
                          {JSON.parse(user.ambassador.photo_cni).map(
                            (image, index) => (
                              <div key={index} className={"w-full flex justify-center"}>
                                <img src={image} alt="img" className="h-36" />
                              </div>
                            )
                          )}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar2 />
          </div>
        )}
      </>
    );
}

 export default ProfilManager;
import { useEffect, useState } from "react";
import Sidebar2 from "../components/sidebar2/Sidebar2";
import { RiRoadMapLine } from "react-icons/ri";
import LoadingSpinner from "../components/Loading";

const ProfilCompany = ({ title, user }) => {
  useEffect(() => {
    document.title = `${title} - Ambassadeur`;
  }, [title]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} />
      ) : (
        <div className="flex gap-4  lg:w-[54vw]">
          <div className="flex-1 mx-auto max-w-screen-2xl space-y-5">
            <div
              className={
                "rounded-2xl bg-white shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]"
              }
            >
              <div
                className={
                  "shadow-md p-5 text-lg lg:text-xl font-medium bg-white rounded-t-2xl lg:px-8"
                }
              >
                PROFIL DE L&apos;ENTREPRISE
              </div>
              <div
                className={
                  "border-gray-100  flex flex-col space-y-5 justify-center p-5 lg:px-16"
                }
              >
                <div
                  className={
                    "bg-blue-600 text-white rounded-t-lg text-center text-lg lg:text-3xl font-bold py-5"
                  }
                >
                  {user.ambassador.entreprise}
                </div>
                <div className={"px-2 lg:px-10"}>
                  <div className={"pb-5 border-b"}>
                    <div className={"text-2xl text-gray-600"}>
                      Nom de l&apos;établissement
                    </div>
                    <div className={"text-2xl"}>
                      {user.ambassador.entreprise}
                    </div>
                  </div>
                  <div className={"py-5 border-b"}>
                    <div className={"text-2xl text-gray-600 pb-2"}>
                      Domaine d&apos;activité
                    </div>
                    <div className={"text-base flex gap-4"}>
                      {JSON.parse(user.ambassador.domaines).map(
                        (domaine, index) => (
                          <div
                            key={index}
                            className={
                              "bg-green-500 text-white rounded px-2 py-1"
                            }
                          >
                            {domaine}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className={"py-5 border-b grid lg:grid-cols-2"}>
                    <div>
                      <div className={"text-2xl text-gray-600"}>Contact </div>
                      <div className={"text-2xl"}>
                        +{user.ambassador.telephone}
                      </div>
                    </div>
                    <div>
                      <div className={"text-2xl text-gray-600"}>Email </div>
                      <div className={"text-2xl"}> {user.email}</div>
                    </div>
                  </div>
                  <div className={"py-5 border-b"}>
                    <div className={"text-2xl text-gray-600"}>Contact </div>
                    <div className={"grid lg:grid-cols-3"}>
                      <div>
                        <div className={"text-2xl text-gray-600 text-center"}>
                          PAYS
                        </div>
                        <div className={"text-base text-center"}>
                          {user.country}
                        </div>
                      </div>
                      <div>
                        <div className={"text-2xl text-gray-600 text-center"}>
                          VILLE
                        </div>
                        <div className={"text-base text-center"}>
                          {user.city}
                        </div>
                      </div>
                      <div>
                        <div className={"text-2xl text-gray-600 text-center"}>
                          QUARTIER
                        </div>
                        <div className={"text-base text-center"}>
                          {user.ambassador.address}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"py-5 border-b"}>
                    <div className={"flex gap-5"}>
                      <div>
                        <RiRoadMapLine size={50} className={"text-blue-600"} />
                      </div>
                      <div className={"text-base flex flex-col gap-1"}>
                        <div>Indiquez la localisation sur le Maps</div>
                        <div className={"text-sm text-blue-600"}>
                          place/angré,@5.3933939339;-3.33939393939339
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "flex flex-col py-5 px-5 border rounded-lg my-10"
                    }
                  >
                    <div>Registre de commerce</div>
                    <div className={"grid lg:grid-cols-2 py-5 gap-5"}>
                      {JSON.parse(user.ambassador.photo_registre).map(
                        (image, index) => (
                          <div key={index} className={"w-full"}>
                            <img src={image} alt="img" />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    className={"flex flex-col py-5 px-5 border rounded-lg my-5"}
                  >
                    <div>Photo du siège</div>
                    <div className={"grid lg:grid-cols-3 py-5 gap-5"}>
                      {JSON.parse(user.ambassador.photo_siege).map(
                        (image, index) => (
                          <div key={index} className={"w-full"}>
                            <img src={image} alt="img" className="h-40" />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Sidebar2 />
        </div>
      )}
    </>
  );
};

export default ProfilCompany;

import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import OtpInput from "react18-input-otp";
import { LiaUnlockAltSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner, { ToastSweet } from "./Loading";
import Api from "../const/api";

const ModalComponent = ({
  open = false,
  setOpen,
  email,
  setIsAuthenticated,
}) => {
  ModalComponent.propTypes = {
    email: PropTypes.string,
    setIsAuthenticated: PropTypes.func.isRequired,
  };

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(Api.login, {
        email,
        password: otp,
      });

      const {token, ...data} = response.data.user;

      localStorage.setItem("token", token);
      localStorage.setItem("data", JSON.stringify(data));
      console.log(data)
      setIsAuthenticated(true);
      setOpen(false);
      ToastSweet.fire({
        icon: "success",
        title: response?.data?.message || "Code envoyé",
      });
      navigate("ambassador/dashboard");
    } catch (err) {
      Swal.fire({
        title: "Erreur",
        text: err.response?.data?.message || "Une erreur s'est produite",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <LoadingSpinner loading={loading} />

      <Modal show={open} onClose={() => setOpen(false)}>
        <Modal.Header className={"shadow-md py-4"}>
          Confirmation du compte
        </Modal.Header>
        <Modal.Body>
          <div
            className={"flex flex-col items-center justify-center space-y-10"}
          >
            <LiaUnlockAltSolid size={80} />
            <div className={"flex flex-col "}>
              <div className={"lg:w-[20vw]"}>
                <span>
                  Saisissez le code de vérification envoyé sur votre boite mail
                  :
                </span>
                <span className={"text-blue-600 mx-2"}>{email}</span>
              </div>
            </div>
            <div className={"flex flex-col justify-center items-center"}>
              <OtpInput
                id="myInput"
                placeholder="     "
                value={otp}
                onChange={handleChange}
                numInputs={5}
                isSuccessed={false}
                errorStyle="error"
                successStyle="success"
                inputStyle={"text-3xl card__code__opt"}
                containerStyle={"p-2"}
                separator={<span className={"mx-2"}> </span>}
                separateAfter={1}
                shouldAutoFocus
                // onSubmit={() => handleSubmit}
              />
              <div
                className={
                  "flex justify-start text-left font-light text-sm pt-10"
                }
              >
                Renvoyez le code
              </div>
            </div>
            <Button
              onClick={handleSubmit}
              color={"yellow"}
              className={
                "bg__color__yellow text-white hover:text-black py-2 text-xl font-bold"
              }
            >
              SE CONNECTER
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;

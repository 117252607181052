import Api from "../const/api";
import axios from "../const/config";

export class RecruiterService {
  async recruiters() {
    try {
      const res = await axios.get(Api.recruiters);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async recruiter(id) {
    try {
      const res = await axios.get(`${Api.recruiter}${id}`);
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
  }

  async sendOTP(formData) {
    try {
      const res = await axios.post(Api.recruiterSendOTP, formData);
      console.log(res)
      return {
        status: true,
        res,
      };
    } catch (e) {
      console.error(e);
      return { status: false, e };
    }
    // const formData = new FormData();
  }

  async store(formData) {
    try {
      const res = await axios.post(Api.recruiterStore, formData);
      return {
        status: true,
        res,
      };
    } catch (e) {
      return { status: false, e };
    }
  }
}

import { Modal } from "flowbite-react";
const ModalComponent = ({open = false, size='md', setOpen, header = false, textHeader, className, children}) => {


    return (
        <>
            <Modal className={'bg-gray-500 bg-opacity-50'} show={open} onClose={() => setOpen(false)} size={size}>
                {
                    header && <Modal.Header className={'shadow-md py-4'}>{textHeader}</Modal.Header>
                }
                <Modal.Body className={className}>
                    {children}
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ModalComponent;
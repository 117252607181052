import React, { useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/ambassador.png';

import { FiMapPin } from "react-icons/fi";
import { GrSearchAdvanced } from "react-icons/gr";
import { FaPeopleRoof } from "react-icons/fa6";
import { PiListThin } from "react-icons/pi";
import {RiCustomerService2Line, RiHomeSmile2Line, RiUserSearchLine} from "react-icons/ri";
import {
  MdOutlineBorderAll,
  MdOutlineDeliveryDining,
  MdOutlineQueryStats,
  MdStarBorderPurple500,
  MdWorkOutline
} from "react-icons/md";
import { LiaUserAltSolid } from "react-icons/lia";
import {TbMessageCircleQuestion, TbReorder, TbTransfer} from "react-icons/tb";
import { IoIosAddCircleOutline, IoMdClose } from "react-icons/io";
import LinkSidebar from './LinkSidebar';
import { IoWalletOutline } from 'react-icons/io5';
import {BsMinecartLoaded} from "react-icons/bs";
import {CiLink} from "react-icons/ci";

const Sidebar = ({ sidebarOpen, setSidebarOpen, link, setLink }) => {
  const navigate = useNavigate();

  const handleDashboard = () => {
    setLink(0);
    navigate("dashboard");
  };


  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const sidebarExpanded =
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true';

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={`bg-white absolute left-0 top-0 z-40 flex h-screen w-[18vw] flex-col overflow-y-hidden duration-300 ease-linear lg:static lg:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="dashboard" className={"pt-10 "}>
          <img src={logo} className={"w-36 lg:w-52"} alt="Logo" />
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <IoMdClose size={25} />
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}

          <div>
            <div className="flex">
              <div
                className={"px-3 pl-6 cursor-pointer"}
                onClick={() => handleDashboard()}
              >
                <PiListThin
                  size={50}
                  className={`${link === 0 ? "bg-orange-50" : ""
                    } p-2 rounded-t-2xl`}
                />
              </div>

              {link === 1 && (
                <div className={"px-6 cursor-pointer"}>
                  <FiMapPin
                    size={50}
                    className={
                      "bg-orange-50 text__color__yellow p-2 rounded-t-2xl"
                    }
                  />
                </div>
              )}
              {link === 2 && (
                <div className={"px-6 cursor-pointer"}>
                  <GrSearchAdvanced
                    size={50}
                    className={
                      "bg-orange-50 text__color__yellow p-2 rounded-t-2xl"
                    }
                  />
                </div>
              )}
              {link === 3 && (
                  <div className={"px-6 cursor-pointer"}>
                    <BsMinecartLoaded
                        size={50}
                        className={
                          "bg-orange-50 text__color__yellow p-2 rounded-t-2xl"
                        }
                    />
                  </div>
              )}
              {link === 4 && (
                  <div className={"px-6 cursor-pointer"}>
                    <RiUserSearchLine
                        size={50}
                        className={
                          "bg-orange-50 text__color__yellow p-2 rounded-t-2xl"
                        }
                    />
                  </div>
              )}
            </div>
            {link === 0 && (
              <div className={"bg-orange-50 rounded-2xl py-4 text-gray-700"}>
                <h3 className="mb-4 text-center text-xl border-b border-amber-100 py-1 pb-2">
                  MENU
                </h3>

                <ul className="mb-6 flex flex-col gap-4 px-4 text-lg">
                  <LinkSidebar
                    Icon={RiHomeSmile2Line}
                    link={"dashboard" || "/"}
                    title={"Accueil"}
                  />
                  <LinkSidebar
                    Icon={MdWorkOutline}
                    link={"profil_company"}
                    title={"Profil de l'entreprise"}
                  />
                  <LinkSidebar
                    Icon={LiaUserAltSolid}
                    link={"profil_manager"}
                    title={"Profil du gérant"}
                  />
                  <LinkSidebar
                    Icon={TbMessageCircleQuestion}
                    link={"helpers"}
                    title={"Center d'aide"}
                  />
                  <LinkSidebar
                    Icon={RiCustomerService2Line}
                    link={"assistant_service"}
                    title={"Service d'assistance"}
                  />
                </ul>
              </div>
            )}
            {link === 1 && (
              <div className={"bg-orange-50 rounded-2xl py-4 text-gray-700"}>
                <h3 className="mb-4 text-center text-lg font-bold text__color__yellow border-b border-amber-100 py-1 pb-2">
                  POINT RELAIS
                </h3>

                <ul className="mb-6 flex flex-col gap-4 px-4 text-lg">
                  <LinkSidebar
                    Icon={MdOutlineDeliveryDining}
                    link={"relay_point/delivery"}
                    title={"Livraison"}
                  />
                  <LinkSidebar
                      Icon={IoIosAddCircleOutline}
                      link={"relay_point/add-order"}
                      title={"Recherche commande"}
                  />
                  <LinkSidebar
                    Icon={MdOutlineBorderAll}
                    link={"relay_point/orders"}
                    title={"Commandes"}
                  />

                  <LinkSidebar
                    Icon={IoWalletOutline}
                    link={"relay_point/wallet"}
                    title={"Portefeuille"}
                  />
                </ul>
              </div>
            )}
            {link === 2 && (
              <div className={"bg-orange-50 rounded-2xl py-4 text-gray-700"}>
                <h3 className="mb-4 text-center text-lg font-bold text__color__yellow border-b border-amber-100 py-1 pb-2">
                  INSCRIPTION RECRUTEUR
                </h3>

                <ul className="mb-6 flex flex-col gap-4 px-4 text-lg">
                  <LinkSidebar
                    Icon={FaPeopleRoof}
                    link={"recruiter_registration/recruiters"}
                    title={"Les récruteurs"}
                  />
                  <LinkSidebar
                    Icon={IoIosAddCircleOutline}
                    link={"recruiter_registration/add-recruiter"}
                    title={"Ajout"}
                  />
                  <LinkSidebar
                    Icon={IoWalletOutline}
                    link={"recruiter_registration/wallet"}
                    title={"Portefeuille"}
                  />
                </ul>
              </div>
            )}
            {link === 3 && (
                <div className={"bg-orange-50 rounded-2xl py-4 text-gray-700"}>
                  <h3 className="mb-4 text-center text-lg font-bold text__color__yellow border-b border-amber-100 py-1 pb-2">
                    COMPTE VENDEUR
                  </h3>

                  <ul className="mb-6 flex flex-col gap-4 px-4 text-lg">
                    <LinkSidebar
                        Icon={TbReorder}
                        link={"seller_account/orders"}
                        title={"Mes commandes "}
                    />
                    <LinkSidebar
                        Icon={TbTransfer}
                        link={"seller_account/barters"}
                        title={"Mes trocs"}
                    />
                    <LinkSidebar
                        Icon={MdStarBorderPurple500}
                        link={"seller_account/favorites"}
                        title={"Mes favories"}
                    />
                    <LinkSidebar
                        Icon={CiLink}
                        link={"seller_account/sales-link"}
                        title={"Mes liens de vente"}
                    />
                    <LinkSidebar
                        Icon={IoWalletOutline}
                        link={"seller_account/wallet"}
                        title={"Portefeuille"}
                    />
                  </ul>
                </div>
            )}
            {link === 4 && (
                <div className={"bg-orange-50 rounded-2xl py-4 text-gray-700"}>
                  <h3 className="mb-4 text-center text-lg font-bold text__color__yellow border-b border-amber-100 py-1 pb-2">
                    COMPTE RECRUTEUR
                  </h3>

                  <ul className="mb-6 flex flex-col gap-4 px-4 text-lg">
                    <LinkSidebar
                        Icon={FaPeopleRoof}
                        link={"recruiter_account/sellers-list"}
                        title={"Liste des recrus"}
                    />
                    <LinkSidebar
                        Icon={IoIosAddCircleOutline}
                        link={"recruiter_account/add-seller"}
                        title={"Ajout de vendeur"}
                    />
                    <LinkSidebar
                        Icon={MdOutlineQueryStats}
                        link={"recruiter_account/statistic"}
                        title={"Statistique"}
                    />
                    <LinkSidebar
                        Icon={IoWalletOutline}
                        link={"recruiter_account/wallet"}
                        title={"Portefeuille"}
                    />
                  </ul>
                </div>
            )}
          </div>
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;

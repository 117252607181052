export const users = [
    {
        id: 1,
        fullName : 'TRAORE Lamine',
        email: 'traorela@gmail.com',
        phone: '+225 070405152',
        country : 'Côte d\'ivoire',
        city : 'Abidjan',
        badge : true,
        badgeDate : '12/12/2023',
        createdAt: '14/12/2023',
        image: 'https://randomuser.me/api/portraits/men/75.jpg',
    },
    {
        id: 2,
        fullName : 'Yao Kouamé',
        email: 'yao@gmail.com',
        phone: '+225 0720300152',
        country : 'Côte d\'ivoire',
        city : 'Bouaké',
        badge : false,
        createdAt: '02/12/2023',
        image: 'https://randomuser.me/api/portraits/men/92.jpg',
    },
    {
        id: 3,
        fullName : 'Kadio Serge Kouamé',
        email: 'kadio@gmail.com',
        phone: '+225 0120303252',
        country : 'Côte d\'ivoire',
        city : 'Bouaflé',
        badge : false,
        createdAt: '10/01/2024',
        image: 'https://randomuser.me/api/portraits/men/20.jpg',
    },
];

export const products = [
    {
        id: 1,
        name : 'Ordinateur HP i5 8ieme Génération',
        state : 'Neuf',
        price : 123000,
        price_normal : 150000,
        description : 'PC pour tous',
        image : 'https://i0.wp.com/pcmarket.ci/up/2023/11/Dell-Latitude-3310-Pentium-2.jpg?resize=300%2C300&ssl=1',
        createdAt: '14/12/2023',
        relayPoint : 'Commission retiré',
    },
    {
        id: 2,
        name : 'Chaussure VapoMax',
        state : 'Neuf',
        price : 34000,
        price_normal : 36000,
        description : 'Paire pour tous',
        image : 'https://static.ftshp.digital/img/p/1/0/9/4/3/4/1/1094341-full_product.jpg',
        createdAt: '14/12/2023',
        relayPoint : 'Colis en route',
    },
    {
        id: 3,
        name : 'Renaud 408 2023',
        state : 'Neuf',
        price : 12000000,
        description : 'Voiture pour tous',
        image : 'https://static1.caroom.fr/guide/wp-content/uploads/2023/01/voiture-electrique-2023-1200x643.jpg',
        createdAt: '19/12/2023',
        relayPoint : 'Voiture en maintenance',
    },
];

export const orders = [
    {
        id: 1,
        user : users[0],
        code : 'ZKZKZ293939',
        product : products[0],
        quantity : 2,
        createdAt: '25/12/2023',
        place_delivery : 'Yamoussoukro',
        price_delivery : 2500,
        date_delivery : '27/12/2023',
        hour_delivery : '12:00',
        description : 'PC pour tous',
    },
    {
        id: 2,
        user : users[1],
        code : 'ZKZKZ2939',
        product : products[1],
        quantity : 3,
        createdAt: '19/06/2023',
        place_delivery : 'Bouaké',
        price_delivery : 4500,
        date_delivery : '01/07/2023',
        hour_delivery : '15:30'
    },
    {
        id: 3,
        user : users[2],
        code : 'AJDNDN2929',
        product : products[2],
        quantity : 1,
        createdAt: '19/12/2023',
        place_delivery : 'Abidjan',
        price_delivery : 1500,
        date_delivery : '24/12/2023',
        hour_delivery : '09:00'
    },
]

import img from '../../assets/product.webp'
import {TiPinOutline} from "react-icons/ti";
const OrderCardPin = () => {
    return (
        <>
            <div className="flex flex-1 flex-col p-1 rounded-xl bg-white relative shadow">
                <div className="absolute top-0 right-0 flex gap-2 ">
                    <div className="p-1"><TiPinOutline size={20} /></div>
                    <div className="p-1 px-4  text-sm bg__color__yellow rounded-l-lg rounded-tr-xl">Neuf</div>
                </div>
                <div className="md:flex justify-between py-3 md:py-1 mx-1 border-b">
                    <div className="flex gap-6">
                        <div className={'w-32 h-32 '}>
                            <img src={img} alt="product" className={'w-32 h-32 rounded-xl'} />
                        </div>
                        <div className="flex flex-col py-2">
                            <div className="font-medium text-lg md:text-2xl two__line__container">
                                Compllet tunique pour homme taille standart
                            </div>
                            <div className="font-light text-sm md:text-base">
                                Blanc
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-end">
                        <div className="text-blue-600 text-xl font-bold text-center md:text-left">14.000 FCFA</div>
                    </div>
                </div>
                <div className="flex justify-between py-1 px-2 items-center">
                    <div className="">Il y&apos;a 3 min</div>
                    <button className={'py-2 px-7 bg-blue-500 text-white font-bold rounded-xl'}>Analyse</button>
                </div>
            </div>
        </>
    )
}

export default OrderCardPin;
import CardLogin from "../components/CardLogin";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import Modal from "../components/ModalLogin";
import Header from "../components/Header";
import Alert from "@uiw/react-alert";
import axios from "axios";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import LoadingSpinner, { ToastSweet } from "../components/Loading";
import Api from "../const/api";

const Login = ({ setIsAuthenticated }) => {

  Login.propTypes = {
    setIsAuthenticated: PropTypes.func.isRequired,
  };

  useEffect(() => {
    document.title = "Page de connexion";
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSendOTP = async (e) => {
    if (email) {
      setLoading(true);
      try {
        const response = await axios.post(Api.sendOTP, {
          email,
        });
        ToastSweet.fire({
          icon: "success",
          title: response?.data?.message || "Code envoyé",
        });
        
        setOpenModal(true);
      } catch (err) {
        Swal.fire({
          title: "Erreur",
          text: err.response?.data?.message || "Une erreur s'est produite",
          icon: "error",
        });
      }
      setLoading(false);
    } else setOpenAlert(true);
  };

  return (
    <>
      <Alert
        isOpen={openAlert}
        confirmText="Retour"
        onClosed={() => setOpenAlert(false)}
        content="Veuillez renseigner votre email svp!"
      />
      <LoadingSpinner loading={loading} />
      <Header />
      <div
        className={
          "flex justify-center items-center md:max-w-[80rem] m-auto h-[90vh]"
        }
      >
        <div className={"lg:grid grid-cols-3 gap-8"}>
          <CardLogin
            className={
              "bg__color__yellow col-span-1 space-y-10 lg:h-[57vh] relative hidden lg:block"
            }
          >
            <div className="absolute top-2.5 left-2.5 lg:h-[55vh] w-4 bg-amber-50 bg-opacity-50 rounded-2xl"></div>
            <div className="text-4xl text-white uppercase font-semibold">
              Bienvenue chez daymond
            </div>

            <div className="text-2xl text-red-950 uppercase font-semibold">
              Connectez-vous maintenant
            </div>

            <div className="text-xl text-white">
              Et profitez des avantages de Daymond
            </div>
          </CardLogin>
          <CardLogin className={"col-span-2"}>
            <div className="space-y-10 flex flex-col">
              <div className="mb-6 mt-16">
                <label
                  className="block text-gray-700 text-base font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none text-base border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Entrez votre email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="text-red-500 text-xs italic pt-3">
                  Please choose a email.
                </p>
              </div>

              <div className="flex items-center justify-between">
                <Button
                  type={"button"}
                  label={"Se connecter"}
                  className={"bg__color__yellow"}
                  onClick={handleSendOTP}
                />
              </div>
            </div>
          </CardLogin>
        </div>
      </div>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        email={email}
        setIsAuthenticated={setIsAuthenticated}
      />
    </>
  );
};

export default Login;

import { Routes, Route } from "react-router-dom";
import Orders from "../../pages/relay_point/Orders";
import AddOrder from "../../pages/relay_point/AddOrder";
import Wallet from "../../pages/relay_point/WalletRelayPoint";
import Delivery from "../../pages/relay_point/Delivery";

const RelayPoint = ({user}) => {

    return (
      <>
        <Routes>
          <Route
            path="/delivery"
            element={<Delivery user={user} title={"Livraison"} />}
          />
          <Route
            path="/orders"
            element={<Orders user={user} title={"Commandes"} />}
          />
          <Route
            path="/add-order"
            element={<AddOrder user={user} title={"Ajout de commande"} />}
          />
          <Route
            path="/wallet"
            element={<Wallet user={user} title={"Portefeuille"} />}
          />
        </Routes>
      </>
    );
}

export default RelayPoint;
